export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRODUCTS_FILTERED = 'SET_PRODUCTS_FILTERED';
export const SET_ONE_PRODUCT = 'SET_ONE_PRODUCT';
export const SET_PRODUCTS_FILTER = 'SET_PRODUCTS_FILTER';
export const SET_PRODUCTS_QUANTITY = 'SET_PRODUCTS_QUANTITY';
export const SET_PRODUCTS_LOADING = 'SET_PRODUCTS_LOADING';
export const SET_ONE_PRODUCT_LOADING = 'SET_ONE_PRODUCT_LOADING';
export const SET_ADMIN_PRODUCTS = 'SET_ADMIN_PRODUCTS';
export const SET_ADMIN_PANEL_PRODUCTS = 'SET_ADMIN_PANEL_PRODUCTS';
export const SET_ADMIN_ONE_PRODUCT = 'SET_ADMIN_ONE_PRODUCT';
export const SET_ADMIN_PANEL_ONE_PRODUCT = 'SET_ADMIN_PANEL_ONE_PRODUCT';


// export const SET_PRODUCTS_STYLE = 'SET_PRODUCTS_STYLE';
