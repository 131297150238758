import {
  SET_USER,
  SET_ALL_USER,
  SET_USER_ISAUTH,
  SET_USER_ISLOADING
} from './types';

const initialState = {
  data: {},
  getAll: [],
  isAuth: false,
  isLoading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      return { ...state, data: action.payload };
    }
    case SET_ALL_USER: {
      return { ...state, getAll: action.payload };
    }
    case SET_USER_ISAUTH: {
      return { ...state, isAuth: action.payload };
    }
    case SET_USER_ISLOADING: {
      return { ...state, isLoading: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
