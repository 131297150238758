import { $adminHost } from './index';

// @route   POST /statuses
// @desc    Create statuses
// @access  Private
export const create = async status => {
  const res = await $adminHost.post('statuses/create', status).catch(err => {
    throw err;
  });
  return res;
};

// @route   PUT /statuses
// @desc    Update statuses
// @access  Private
export const update = async (id, status) => {
  const res = await $adminHost.put(`statuses/update/${id}`, status).catch(err => {
    throw err;
  });
  return res;
};

// @route   DELETE /statuses
// @desc    Delete statuses
// @access  Private
export const deleteOne = async id => {
  const res = await $adminHost.delete(`statuses/delete/${id}`).catch(err => {
    throw err;
  });
  return res;
};

// @route   GET /statuses
// @desc    Get statuses 
// @access  Private
export const getAll = async () => {
  const res = await $adminHost.get('statuses/getall').catch(err => {
    throw err;
  });
  return res;
};
// @route   GET /statuses
// @desc    Get statuses
// @access  Private
export const getById = async id => {
    const res = await $adminHost.get(`statuses/get/${id}`).catch(err => {
      throw err;
    });
    return res;
  };