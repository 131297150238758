import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getDate } from '../../../../utils/func';
import styles from './Order.module.scss';
import { Button } from 'react-bootstrap';
const PRODUCT_ROUTE = "products/item/";

const Order = order => {
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState('0px');
  const content = useRef(null);
  const {
    status,
    firstName,
    email,
    phone,
    lastName,
    orderNo,
    date,
    totalSum,
    paymentInfo,
    deliveryAddress,
    products,
    // eslint-disable-next-line react/destructuring-assignment
  } = order.order;

  let statusElement = null;

  if (status === 'specified') {
    statusElement = <p style={{ color: '#FF0000' }}>Уточнюється</p>;
  } else if (status === 'processed') {
    statusElement = <p style={{ color: '#FF8C00' }}>Обробляється</p>;
  } else if (status === 'send') {
    statusElement = <p style={{ color: '#0000FF' }}>Відправлено</p>;
  } else if (status === 'completed') {
    statusElement = <p style={{ color: '#008000' }}>Виконано</p>;
  } else if (status === 'abolition') {
    statusElement = <p style={{ color: 'red' }}>Відмінено</p>;
  }
  const openDescription = () => {
    setActive(!active);
    setHeight(active ? '0px' : `${content.current.scrollHeight}px`);
  };

  const orderedProducts = products.map(i => {
    const { product } = i;
    const { _id: id } = product;
    return (
      <div key={id} className={styles.productItem}>
        <NavLink to={`/${PRODUCT_ROUTE}${product._id}`} className={styles.productLink}>
          <img src={product.imageFirst} width={100} alt='' />
        </NavLink>
        <div className={styles.productDescription}>
          <NavLink to={`/${PRODUCT_ROUTE}${product._id}`} className={styles.productLink}>
            {product.name}
          </NavLink>
          <p>Ціна: {product.currentPrice}</p>
          <p>Кількість: {i.cartQuantity}</p>
        </div>
      </div>
    );
  });

  return (
    <div className={`${styles.orderWrapper} ${active}`}>
      <div className={styles.orderHeader}>
        <div>Замовлення №{orderNo}</div>
        <div>{totalSum.$numberDecimal} грн</div>
      </div>
      <div className={styles.orderDetails}>
        <div>
          {products.map(product => {
            const { _id: id } = product;
            return (
              <p key={id} className={styles.productName}>
                {product.product.name}
              </p>
            );
          })}
        </div>
        <div>
          <span className={styles.date}>Дата замовлення: {getDate(date)}</span>
        </div>
        <div className={styles.buttonWrapper}>
          <Button variant='dark' onClick={() => openDescription()}>Детальна інформація</Button>
        </div>
      </div>

      <div ref={content} style={{ height: `${height}` }} className={styles.orderDescription}>
        <hr />

        <div className={styles.userDataTable}>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Статус</p>
            {statusElement}
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Ім'я та прізвище</p>
            <p>
              {firstName} {lastName}
            </p>
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Електронна пошта</p>
            <p>{email}</p>
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Номер телефону</p>
            <p>{phone}</p>
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Місто</p>
            {deliveryAddress.city ? <p>{deliveryAddress.city}</p> : <p style={{ color: 'red' }}>Місто не вказане</p>}
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Область</p>
            {deliveryAddress.region ? (
              <p>{deliveryAddress.region}</p>
            ) : (
              <p style={{ color: 'red' }}>Область не вказана</p>
            )}
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Адреса</p>
            {deliveryAddress.address ? (
              <p>{deliveryAddress.address}</p>
            ) : (
              <p style={{ color: 'red' }}>Адреса не вказана</p>
            )}
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Спосіб доставки</p>
            {deliveryAddress.delivery ? (
              <p>{deliveryAddress.delivery}</p>
            ) : (
              <p style={{ color: 'red' }}>Спосіб доставки не вказаний</p>
            )}
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Спосіб оплати</p>
            <p>{paymentInfo}</p>
          </div>
        </div>
        <div className={styles.productPreview}>
          <p>Ваше замовлення</p>
          <hr />
          {orderedProducts}
          <span className={styles.totalPrice}>Разом до оплати: {totalSum.$numberDecimal} грн</span>
        </div>
      </div>
    </div>
  );
};

export default Order;
