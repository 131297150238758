import { $host } from './index';

// @route   GET /all blogs
// @desc    Get new blogs
// @access  Private
export const getAll = async () => {
    const res = await $host.get('blog/getall').catch(err => {
      throw err.response;
    });
    return res;
};
// @route   GET /all blogs
// @desc    Get new blogs
// @access  Private
export const getOne = async (id) => {
    const res = await $host.get(`blog/get/${id}`).catch(err => {
      throw err.response;
    });
    return res;
};