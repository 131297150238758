import React, { useEffect, useState } from "react";
import "./MenuProducts.scss";
import { NavLink } from "react-bootstrap";
import ua from "../../../language/ua.json";
import { Link } from "react-router-dom";

const MenuProducts = ({ open,setOpen, menuList}) => {
  const [menuItems, setMenuItems] = useState([]);

  const menu = () => {
    const links = menuList.map((item, index) => (
      // <Link key={index} to={"../" + item.url}>{ua[item.name]}</Link>
      <Link key={index} to={`/products/categories/${item.title}`}>{item.name}</Link>
    ));
    setMenuItems(links);
  }
  
  // Викликаємо функцію menu() один раз при монтуванні компонента
  useEffect(() => {
    if(menuList.length > 0){
      menu(); 
    }
  }, [menuList]);

  return(
    <nav className={`menu__products ${open ? 'open__products' : ''}`}>
      {menuItems}
    </nav>
  )
}
  
export default MenuProducts;