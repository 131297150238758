import React from "react";
import styles from "./Index.module.scss";

const Index = () => {
    const backgroundImage = "https://wallpapercave.com/wp/wp1879435.jpg";
    return (
        <>
            <section style={{ backgroundImage: `url(${backgroundImage})` }} className={styles.container}>
            </section>
        </>
    );
};

export default Index;