import React, { useState, useEffect } from 'react';
import { Table, Modal, Button } from 'react-bootstrap';
import { useHttp } from '../../../hooks/http.hook';
import { useMessageError, useMessageSuccess } from '../../../hooks/message.hook';
import FormWrapper from './FormWrapper/FormWrapper';
import Container from '../../../components/Container/Container';
import BurgerMenu from '../../../components/BurgerMenu/BurgerMenu';
import "./Categories.scss";

const Categories = () => {
    const { request } = useHttp();
    const messageError = useMessageError();
    const messageSuccess = useMessageSuccess();
    const [showModal, setShowModal] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const [delId, setDelId] = useState();
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categories, setCategories] = useState([]);
    const [discounts, setDiscounts] = useState([]);

    const fetchCategories = async () => {
        try {
            const data = await request('admin/categories/getall', 'GET');
            setCategories(data.data);
        } catch (error) {
            messageError(error);
        }
    };
    const fetchDiscounts = async () => {
        try {
            const data = await request('admin/discounts/getall', 'GET');
            setDiscounts(data.data);
        } catch (error) {
            messageError(error);
        }
    };
    useEffect(() => {
        fetchCategories();
        fetchDiscounts();
    }, []);

    const handleDelete =  (id) => {
        setShowDel(true);
        setDelId(id)
    };
    const handleDeleteAccess = async () => {
        setShowDel(false);
        try {
            const data = await request(`admin/categories/delete/${delId}`, 'DELETE');
            messageSuccess(data.message);
            setDelId("")
            fetchCategories();
        } catch (error) {
            messageError(error);
        }
    };

    const handleCreate = () => {
        setShowModal(true);
        setSelectedCategory(null);
    };
    const handleEdit = (id) => {
        setSelectedCategory(id);
        setShowModal(true);
    };
    const handleCloseModal = (success = false) => {
        setShowModal(false);
        if(success) {fetchCategories();}
    };
    const handleCloseDel = () => {
        setShowDel(false);
        setDelId("")
    }

    return (
        <>
            <Container>                
                <BurgerMenu />
                <h2>Всі категорії</h2>
                <p>Kількість: <span>{categories.length}</span></p>
                <div className='d-flex justify-content-end'>
                    <Button variant="dark" className='mb-3' onClick={() => handleCreate()}>Створити</Button>{' '}
                </div>
                
                <div className='table__wrapper'>
                    <Table striped bordered hover className='table__main'>
                        <colgroup>
                            <col className="low" />
                            <col className="middle" />
                            <col className="middle" />
                            <col className="middle" />
                            <col className="middle" />
                            <col className="middle" />
                        </colgroup>
                        <thead className='table__head'>
                            <tr>
                                <th>#</th>
                                <th>Назва</th>
                                <th>Опис</th>
                                <th>Частина меню</th>
                                <th>Статус</th>
                                <th>Взаємодія</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories.length > 0 && categories.map((category, index) => (
                                <tr key={category._id}>
                                    <td>{index + 1}</td>
                                    <td><div>{category.name}<p>title: {category.title}</p></div></td>
                                    <td>{category.description}</td>
                                    <td>{category.isMenu ? "Так" : "Ні" }</td>
                                    <td>{category.active ? "Ввімкнений" : "Вимкнений" }</td>
                                    <td>
                                        <Button variant="secondary" onClick={() => handleEdit(category._id)}>Редагування</Button>{' '}
                                        <Button variant="danger" onClick={() => handleDelete(category._id)}>Видалити</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                            {/* Modal for editing category */}
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedCategory ? "Редагування" : "Створення"} категорії</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedCategory && <FormWrapper discounts={discounts} create={false} edit={true} id={selectedCategory} onSubmit={handleCloseModal} close={handleCloseModal}/>}
                        {!selectedCategory && <FormWrapper discounts={discounts} create={true} edit={false} id={selectedCategory} onSubmit={handleCloseModal} close={handleCloseModal}/>}
                    </Modal.Body>
                </Modal>
                <Modal show={showDel} onHide={handleCloseDel}>
                    <Modal.Header closeButton>
                        <Modal.Title>Видалити</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Button variant="danger" onClick={() => handleDeleteAccess()}>Видалити</Button>
                    </Modal.Body>
                </Modal>
            </Container>
        </>
    );
};

export default Categories;
