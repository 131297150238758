import React from 'react';
import MySelect from '../../../components/MyForms/MySelect/MySelect';
import { Field } from 'formik';
import styles from "./PaymentDataInputs.module.scss" 

const PaymentDataInputs = () => {
  return (
    <div className={styles.containerRadio}>
      {/* <MySelect label='Спосіб оплати' name='paymentInfo'>
        <option>Спосіб оплати</option>
        <option value="LiqPay">LiqPay</option>
        <option value="LPay">LPay</option>
      </MySelect> */}
      
      <div className={styles.wrapperRadio}>
      <svg width="125" height="26" viewBox="0 0 500 104" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="logo_liqpay">
<g id="Group 2">
<g id="Group">
<path id="Path" fillRule="evenodd" clipRule="evenodd" d="M420.99 80.3606L419.842 79.3766C417.054 76.9986 416.562 72.6526 419.104 69.8646L448.953 35.7524C451.331 32.9644 455.677 32.4724 458.465 35.0144L459.613 35.9984C462.401 38.3764 462.893 42.7224 460.351 45.5104L430.503 79.6226C428.125 82.4106 423.942 82.7386 420.99 80.3606Z" fill="url(#paint0_linear)"/>
<path id="Path_2" opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M444.934 41.6564L444.36 41.0004L439.768 46.2484L440.26 46.8224C448.133 54.6944 444.77 62.6485 442.966 65.4365L443.622 64.6985C444.688 63.4685 446.328 61.5825 448.051 59.5325C449.855 56.4164 452.397 49.0364 444.934 41.6564Z" fill="url(#paint1_linear)"/>
<path id="Path_3" opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M454.775 51.9064L456.005 50.5124C456.087 50.3484 456.251 50.1844 456.333 50.0204C455.021 51.4964 453.627 53.0544 452.315 54.6124C453.299 53.5464 454.119 52.6444 454.775 51.9064Z" fill="url(#paint2_radial)"/>
<path id="Path_4" fillRule="evenodd" clipRule="evenodd" d="M460.515 36.8183L430.667 2.70615C428.289 -0.0818615 423.943 -0.573864 421.155 1.96815L420.007 2.95215C417.218 5.33017 416.726 9.67619 419.269 12.4642L444.443 41.0004L445.017 41.6564C452.479 49.1184 449.937 56.4984 448.051 59.6145C449.527 57.8924 451.003 56.1704 452.397 54.6124C453.709 53.0544 455.103 51.4964 456.415 50.0204C457.727 48.5444 458.957 47.1504 459.777 46.2484C462.647 43.4604 463.057 39.6064 460.515 36.8183Z" fill="url(#paint3_linear)"/>
</g>
<g id="Group_2">
<path id="Path_5" fillRule="evenodd" clipRule="evenodd" d="M458.875 80.3607L457.727 79.3767C454.939 76.9987 454.447 72.6526 456.989 69.8646L486.837 35.7524C489.215 32.9644 493.561 32.4724 496.349 35.0144L497.497 35.9985C500.285 38.3765 500.777 42.7225 498.235 45.5105L468.387 79.6227C466.009 82.4107 461.827 82.7387 458.875 80.3607Z" fill="url(#paint4_linear)"/>
<path id="Path_6" opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M483.147 41.3284L482.573 40.6724L477.981 45.9204L478.473 46.4944C486.345 54.3665 482.983 62.3205 481.179 65.1085L481.835 64.3705C482.901 63.1405 484.541 61.2545 486.263 59.2045C488.067 56.0885 490.609 48.7084 483.147 41.3284Z" fill="url(#paint5_linear)"/>
<path id="Path_7" opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M492.577 51.9065L493.807 50.5124C493.889 50.3484 494.053 50.1844 494.135 50.0204C492.823 51.4965 491.429 53.0545 490.117 54.6125C491.101 53.5465 492.003 52.6445 492.577 51.9065Z" fill="url(#paint6_radial)"/>
<path id="Path_8" fillRule="evenodd" clipRule="evenodd" d="M498.317 36.8184L468.468 2.70621C466.09 -0.0818005 461.744 -0.573803 458.956 1.96821L457.808 2.95222C455.02 5.33023 454.528 9.67625 457.07 12.4643L482.244 41.0004L482.818 41.6564C490.281 49.1185 487.738 56.4985 485.852 59.6145C487.328 57.8925 488.804 56.1705 490.199 54.6125C491.511 53.0545 492.905 51.4965 494.217 50.0205C495.529 48.5445 496.759 47.1505 497.579 46.2484C500.449 43.4604 500.859 39.6064 498.317 36.8184Z" fill="url(#paint7_linear)"/>
</g>
</g>
<g id="Group_3">
<path id="Path_9" fillRule="evenodd" clipRule="evenodd" d="M1.80401 80.0325C0.656004 78.8845 0 77.1625 0 75.0305V7.5441C0 5.33009 0.656003 3.60808 2.05001 2.21407C3.44402 0.820066 5.16603 0.164062 7.38004 0.164062C9.59405 0.164062 11.5621 0.820066 12.8741 2.21407C14.2681 3.60808 14.9241 5.33009 14.9241 7.5441V69.7824H47.1503C51.7423 69.7824 54.1203 71.8325 54.1203 75.8505C54.1203 79.8685 51.7423 81.9185 47.1503 81.9185H6.97004C4.75603 81.9185 3.03402 81.2625 1.80401 80.0325Z" fill="#4B4C4B"/>
<path id="Path_10" fillRule="evenodd" clipRule="evenodd" d="M65.7644 80.5244C64.3704 79.1304 63.7144 77.3264 63.7144 75.0304V7.46204C63.7144 5.24803 64.3704 3.52602 65.7644 2.13201C67.1584 0.738004 68.8804 0.0820007 71.0944 0.0820007C73.3084 0.0820007 75.2764 0.738004 76.5885 2.13201C77.9825 3.52602 78.6385 5.24803 78.6385 7.46204V75.0304C78.6385 77.2444 77.9825 79.1304 76.5885 80.5244C75.1944 81.9184 73.3904 82.5744 71.0944 82.5744C68.9624 82.5744 67.1584 81.9184 65.7644 80.5244Z" fill="#4B4C4B"/>
<path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M163.919 97.2525C163.919 95.8585 163.427 94.5465 162.607 93.1525L158.507 87.0025C156.129 83.1485 153.259 80.5244 149.733 79.3764C156.539 76.3424 161.787 71.5864 165.395 65.0264C169.085 58.5483 170.889 50.5943 170.889 41.4922C170.889 33.0462 169.331 25.6661 166.215 19.4341C163.099 13.2841 158.671 8.44605 152.849 5.00203C147.109 1.72201 140.221 0 132.431 0C124.477 0 117.671 1.55801 111.848 5.00203C106.108 8.44605 101.598 13.2021 98.4824 19.4341C95.4484 25.7481 93.8084 33.0462 93.8084 41.4922C93.8084 49.8563 95.3664 57.2363 98.4824 63.5503C101.598 69.7004 106.026 74.5384 111.848 77.9824C117.671 81.2624 124.477 82.9845 132.431 82.9845C135.219 82.9845 137.433 83.4765 139.155 84.2965C140.877 85.1165 142.271 86.4285 143.337 88.2325L150.061 99.3845C150.881 100.615 151.947 101.599 153.095 102.255C154.325 102.911 155.637 103.239 157.031 103.239C158.835 103.239 160.475 102.583 161.869 101.517C163.263 100.451 163.919 98.9745 163.919 97.2525ZM156.047 41.3283C156.047 50.8403 153.915 58.2204 149.733 63.3864C145.551 68.5524 139.729 71.0944 132.267 71.0944C124.805 71.0944 118.901 68.5524 114.719 63.3864C110.537 58.2204 108.405 51.0043 108.405 41.3283C108.405 31.6522 110.537 24.4362 114.719 19.2702C118.901 14.1861 124.723 11.6441 132.267 11.6441C139.811 11.6441 145.551 14.1041 149.733 19.2702C153.915 24.4362 156.047 31.6522 156.047 41.3283Z" fill="#4B4C4B"/>
<path id="Shape_2" fillRule="evenodd" clipRule="evenodd" d="M186.879 75.0304C186.879 77.3265 187.535 79.1305 188.929 80.5245C190.159 81.9185 192.045 82.5745 194.259 82.6565C196.555 82.6565 198.359 82.0005 199.753 80.6065C201.147 79.2945 201.803 77.4905 201.803 75.1124V50.3483H222.385C231.077 50.3483 237.801 48.2163 242.639 43.7883C247.477 39.4422 249.937 33.2922 249.937 25.5022C249.937 17.7121 247.477 11.5621 242.639 7.29807C237.801 2.87005 231.077 0.738037 222.385 0.738037H194.095C191.881 0.738037 190.159 1.39404 188.847 2.54205C187.535 3.77205 186.879 5.49406 186.879 7.70808V75.0304ZM235.834 25.6662C235.834 34.5223 230.667 39.0323 220.499 39.0323L201.803 38.9503V12.3001H220.499C230.667 12.3001 235.834 16.8102 235.834 25.6662Z" fill="#4B4C4B"/>
<path id="Shape_3" fillRule="evenodd" clipRule="evenodd" d="M326.28 80.5244C327.674 79.2124 328.412 77.7364 328.412 76.0144C328.412 74.9484 328.166 74.0464 327.838 72.9804L295.94 4.67403C295.202 3.11602 294.136 1.96801 292.742 1.14801C291.348 0.410002 289.872 0 288.232 0C286.592 0 285.116 0.328002 283.722 1.14801C282.246 1.96801 281.18 3.19802 280.524 4.67403L248.707 72.9804C248.297 73.9644 248.051 75.0304 248.051 76.0144C248.051 77.8184 248.707 79.3764 250.183 80.5244C251.577 81.6724 253.299 82.3284 255.021 82.3284C256.333 82.3284 257.481 82.0004 258.547 81.3444C259.613 80.6884 260.433 79.7044 261.089 78.3104L268.06 62.8943H308.404L315.374 78.3104C315.948 79.6224 316.85 80.6884 317.916 81.3444C318.982 82.0004 320.212 82.3284 321.36 82.3284C323.246 82.3284 324.886 81.6724 326.28 80.5244ZM303.484 51.4143H273.062L288.232 17.3021L303.484 51.4143Z" fill="#4B4C4B"/>
<path id="Path_11" fillRule="evenodd" clipRule="evenodd" d="M385.812 0.164001C387.534 0.164001 389.092 0.820005 390.486 2.21401C391.88 3.60802 392.536 5.08403 392.536 7.05204C392.536 8.52805 391.962 10.0861 390.814 11.4801L364.656 43.3782V75.2764C364.656 77.6544 364 79.4584 362.606 80.8524C361.212 82.2465 359.49 82.8205 357.358 82.8205C355.144 82.8205 353.258 82.1645 351.864 80.8524C350.47 79.5404 349.814 77.7364 349.814 75.2764V43.3782L323.82 11.3981C322.672 10.0041 322.098 8.52805 322.098 6.97004C322.098 5.16603 322.754 3.52602 324.148 2.13201C325.542 0.738004 327.182 0.0820007 328.822 0.0820007C330.79 0.0820007 332.348 0.902005 333.742 2.62401L357.276 31.8162L380.728 2.62401C382.122 0.984006 383.844 0.164001 385.812 0.164001Z" fill="#4B4C4B"/>
</g>
</g>
<defs>
<linearGradient id="paint0_linear" x1="430.313" y1="91.0077" x2="471.737" y2="70.9235" gradientUnits="userSpaceOnUse">
<stop stopColor="#1FADC3"/>
<stop offset="0.7072" stopColor="#36B98F"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="444.859" y1="58.7495" x2="452.602" y2="57.622" gradientUnits="userSpaceOnUse">
<stop stopColor="#123F06" stopOpacity="0.01"/>
<stop offset="1" stopColor="#123F06"/>
</linearGradient>
<radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(438.327 56.1695) scale(19.9933 22.8494)">
<stop offset="0.4185" stopColor="#123F06" stopOpacity="0.01"/>
<stop offset="1" stopColor="#123F06"/>
</radialGradient>
<linearGradient id="paint3_linear" x1="401.983" y1="45.514" x2="452.22" y2="62.8745" gradientUnits="userSpaceOnUse">
<stop stopColor="#9FDB57"/>
<stop offset="1" stopColor="#71CA5E"/>
</linearGradient>
<linearGradient id="paint4_linear" x1="468.161" y1="91.0285" x2="509.585" y2="70.9443" gradientUnits="userSpaceOnUse">
<stop stopColor="#1FADC3"/>
<stop offset="0.7072" stopColor="#36B98F"/>
</linearGradient>
<linearGradient id="paint5_linear" x1="483.071" y1="58.4215" x2="490.814" y2="57.294" gradientUnits="userSpaceOnUse">
<stop stopColor="#123F06" stopOpacity="0.01"/>
<stop offset="1" stopColor="#123F06"/>
</linearGradient>
<radialGradient id="paint6_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(476.163 56.1696) scale(19.9933 22.8494)">
<stop offset="0.4185" stopColor="#123F06" stopOpacity="0.01"/>
<stop offset="1" stopColor="#123F06"/>
</radialGradient>
<linearGradient id="paint7_linear" x1="439.809" y1="45.5291" x2="490.046" y2="62.8896" gradientUnits="userSpaceOnUse">
<stop stopColor="#9FDB57"/>
<stop offset="1" stopColor="#71CA5E"/>
</linearGradient>
</defs>
</svg>
        <Field name="paymentInfo" type="radio" value="LiqPay" id="paymentInfoLiqPay" label="LiqPay" /><label>LiqPay</label></div>
    </div>
  );
};

export default PaymentDataInputs;
