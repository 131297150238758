import React from "react";
import "./Burger.scss";

const Burger = ({ open, setOpen}) => {
  const buttonClass = open ? "burger__products-button open__products" : "burger__products-button";
  return (
    <button className={buttonClass} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </button>
  );
};

export default Burger;