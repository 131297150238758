import React, { useEffect, useState } from "react";
import { useMessageError } from "../../../hooks/message.hook";
import { useHttp } from "../../../hooks/http.hook";
import { Table } from "react-bootstrap";
import Container from "../../../components/Container/Container";
import styles from "./Statistic.module.scss";
// import { Helmet } from "react-helmet-async";

const Statistic = () => {
    const { request } = useHttp();
    const showMessage = useMessageError();
    const [isTableVisible, setIsTableVisible] = useState(false);
    const [goods, setGoods] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [token, setToken] = useState(() => JSON.parse(localStorage.getItem("checkBoxToken")));
    const limit = 100;

    useEffect(() => {
    if (!token) {
        signIn();
    } else {
        setIsTableVisible(true);
        fetchGoods();
    }
    }, [token]);

    useEffect(() => {
        if (isTableVisible) {
            fetchGoods();
        }
    }, [offset, token]);

    const checkToken = async () => {
        const token = JSON.parse(localStorage.getItem("checkBoxToken"));
        if (token) {
            setIsTableVisible(true);
            fetchGoods();
        }
    };

    const signIn = async () => {
        setIsLoading(true);
        try {
            const data = await request('checkbox/login', 'POST', { login: "login", password: "password" });
            setToken(data.access_token);
            localStorage.setItem("checkBoxToken", JSON.stringify(data.access_token));
            setIsTableVisible(true);
        } catch (error) {
            showMessage(error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchGoods = async () => {
        setIsLoading(true);
        try {
            const token = JSON.parse(localStorage.getItem("checkBoxToken"));
            console.log("🚀 ~ fetchGoods ~ token:", token)
            const data = await request(`/checkbox/goods?limit=${limit}&offset=${offset}`, 'GET', null, { token });
            console.log("🚀 ~ fetchGoods ~ data:", data)
            if (data.results.length === 0) {
                showMessage("No goods found.");
            } else {
                setGoods(data.results);
            }
        } catch (error) {
            showMessage(error);
        } finally {
            setIsLoading(false);
        }
    };
    // const signOut = async () => {
    //     try {
    //         const token = JSON.parse(localStorage.getItem("checkBoxToken"));
    //         const data = await request('checkbox/signout', 'POST', { token });
    //         localStorage.removeItem("checkBoxToken");
    //         showMessage(data.message);
    //         // Additional cleanup actions after sign out
    //     } catch (error) {
    //         showMessage(error);
    //     } finally {
    //     }
    // };
    const handleNext = () => setOffset(prevOffset => prevOffset + limit);
    const handleBack = () => setOffset(prevOffset => Math.max(0, prevOffset - limit));

    return (
        <Container>
            {/* <Helmet>
                <title>Statistic Page</title>
                <meta http-equiv="Content-Security-Policy" content="
                    default-src 'self';
                    script-src 'self' https://api.checkbox.ua 'unsafe-inline' 'unsafe-eval';
                    style-src 'self' 'unsafe-inline';
                    img-src 'self' https://api.checkbox.ua https://parfume-bucket-files.s3.eu-central-1.amazonaws.com https://wallpapercave.com;
                    connect-src 'self' https://api.checkbox.ua;
                    media-src 'self';
                "/>
            </Helmet> */}
            <h1>Статистика CheckBox</h1>
            <button type="button" onClick={signIn} disabled={isLoading}>SignIn</button>
            <button type="button" onClick={fetchGoods} disabled={isLoading}>Goods</button>
            {/* <button type="button" onClick={signOut} disabled={isLoading}>SignOut</button> */}
            {isLoading ? <p>Loading...</p> : isTableVisible && (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>ID</th>
                            <th>Назва</th>
                            <th>Вартість</th>
                            <th>Кількість</th>
                        </tr>
                    </thead>
                    <tbody>
                        {goods.length > 0 ? goods.map((item, index) => (
                            <tr key={index}>
                                <td>{index}</td>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.price / 100}.00 грн</td>
                                <td>{item.count}</td>
                            </tr>
                        )) : <tr><td colSpan="5">No goods available</td></tr>}
                    </tbody>
                </Table>
            )}
            <div>
                <button type="button" onClick={handleBack} disabled={isLoading || offset === 0} className={styles.pagination_button}>{`<`}</button>
                <span>Page {Math.floor(offset / limit) + 1}</span>
                <button type="button" onClick={handleNext} disabled={isLoading || goods.length < limit} className={styles.pagination_button}>{`>`}</button>
            </div>
        </Container>
    );
}

export default Statistic;
