import React, { useEffect, useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Button } from 'react-bootstrap';

const DoubleSlider = ({ filterProducts, filter, ordersFilter }) => {
  const [initialValues, setInitialValues] = useState([0, 0]);
  const [values, setValues] = useState();

  useEffect(() => {
    if (filterProducts && filterProducts.filters?.minPrice) {
      const minPrice = filterProducts.filters.minPrice;
      const maxPrice = filterProducts.filters.maxPrice;
      setInitialValues([minPrice, maxPrice]);
    }
    const savedOrdersFilter = JSON.parse(localStorage.getItem("filtersProducts"));

    if (!!savedOrdersFilter?.filter?.currentPrice?.$gte) {
      const minPrice = savedOrdersFilter.filter.currentPrice.$gte;
      const maxPrice = savedOrdersFilter.filter.currentPrice.$lte;
      setValues([minPrice, maxPrice]);
    }
    if(!savedOrdersFilter?.filter?.currentPrice?.$gte && filterProducts && filterProducts.filters?.minPrice){
      const minPrice = filterProducts.filters.minPrice;
      const maxPrice = filterProducts.filters.maxPrice;
      setValues([minPrice, maxPrice]);
    }
  }, [filterProducts]);

  const handleSliderChange = newValues => {
    setValues(newValues);
  };

  const find = () => {
    filter("currentPrice", { $gte: values[0], $lte: values[1] });
  };

  if (!filterProducts) {
    return (<p>Loading...</p>);
  }

  return (
    <div style={{ width: '150px', padding: '20px 5px' }}>
      <div>
        <Slider
          min={initialValues[0]}
          max={initialValues[1]}
          range
          value={values}
          onChange={handleSliderChange}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', color: '#ffffff' }}>
          <span>{values && values[0]}</span>
          <span>{values && values[1]}</span>
        </div>
        <Button variant='light' onClick={find}>OK</Button>
      </div>
    </div>
  );
};

export default DoubleSlider;
