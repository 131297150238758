import React, { useEffect, useState } from "react";
import styles from "./Products.module.scss";
import { Table, Modal, Button } from 'react-bootstrap';
import { useHttp } from "../../../hooks/http.hook";
import { useMessageError, useMessageSuccess } from '../../../hooks/message.hook';
import {  getAdminProductsOperation } from "../../../store/products/operations";
import { getAdminProductsSelector} from "../../../store/products/selectors";
import Product from "./Product/Product";
import Container from "../../../components/Container/Container";
import BurgerMenu from '../../../components/BurgerMenu/BurgerMenu';
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination/Pagination";

const Products = () =>  {
    const dispatch = useDispatch();
    const products = useSelector(getAdminProductsSelector);
    const { request } = useHttp();
    const messageError = useMessageError();
    const messageSuccess = useMessageSuccess();
    const [showModal, setShowModal] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const [delId, setDelId] = useState();
    const [selectedProduct, setSelectedProduct] = useState(null);
    // const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [startPage, setStartPage] = useState(1);
    const perPage = 50; 
    const [refreshOrders, setRefreshOrders] = useState(true);
    const [searchInput, setSearchInput] = useState('');
    let ordersFilter = {};
    if ( searchInput === '') {
      ordersFilter = { perPage, startPage };
    } else {
      ordersFilter = { perPage, startPage, find:searchInput };
    }
  
    const search = (event) => {
      setSearchInput(event);
    //   setRefreshOrders(true);
    }

    const fetchCategories = async () => {
        try {
          const data = await request('admin/categories/getall', 'GET');
          setCategories(data.data);
        } catch (error) {
          messageError(error);
        }
    };

    const fetchBrands = async () => {
        try {
          const data = await request('admin/brands/getall', 'GET');
          setBrands(data.data);
        } catch (error) {
          messageError(error);
        }
    };
    
    const handleDeleteAccess = async () => {
        setShowDel(false);
        try {
            const data = await request(`admin/products/delete/${delId}`, 'DELETE');
            messageSuccess(data.message);
            setDelId("");
            dispatch(getAdminProductsOperation(ordersFilter));
        } catch (error) {
            messageError(error);
        }
    };
    useEffect(() => {
        dispatch(getAdminProductsOperation(ordersFilter));
        setRefreshOrders(false);
    }, [refreshOrders, startPage]); // Оновлення продуктів лише при монтуванні компоненту

    useEffect(() => {
        fetchCategories();
        fetchBrands();
    }, []);

    const handleDelete = async (id) => {
        setShowDel(true);
        setDelId(id);
    };

    const handleActive = async (id) => {
        try {
            const data = await request(`admin/products/active/${id}`, 'PUT');
            messageSuccess(data.message);
            dispatch(getAdminProductsOperation(ordersFilter));
        } catch (error) {
            messageError(error);
        }
    };

    const handleCreate = () => {
        setShowModal(true);
        setSelectedProduct(null);
    };
    const handleEdit = (id) => {
        setSelectedProduct(id);
        setShowModal(true);
    };
    const handleCloseModal = async (success) => {
        setShowModal(false);
        if(success) {
            dispatch(getAdminProductsOperation());
        }
    };
    const handleCloseDel = () => {
        setShowDel(false);
        setDelId("");
    }
    return(
        <>
            <section>
                <BurgerMenu />                
                <Container>
                    <h1>Всі Продукти</h1>
                    <p>Kількість позицій: <span>{products.length}</span></p>
                    <div className="d-flex justify-content-between mb-3">
                        <div className={styles.filter}>
                            <div className={styles.filterWrapper}>
                                <div>
                                    <input onChange={(e) => search(e.target.value)}
                                    name='search' type='text' placeholder="Пошук" tabIndex='0'/>
                                </div>
                                <Button variant="dark" onClick={() => setRefreshOrders(true)}>Пошук</Button>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <Button variant="dark" onClick={() => handleCreate()}>Створити</Button>{' '}
                        </div>
                    </div>

                    <div className='table__wrapper'>
                    <div className='table__wrapper-second'>
                    <Table striped bordered hover className='table__main'>
                        <colgroup>
                            <col className="low" />
                            <col className="middle" />
                            <col className="middle" />
                            <col className="middle" />
                            <col className="middle" />
                            <col className="middle" />
                            <col className="middle" />
                            <col className="middle" />
                        </colgroup>
                        <thead className='table__head'>
                                <tr>
                                    <th>#</th>
                                    <th>ID</th>
                                    <th>Назва</th>
                                    <th>Опис</th>
                                    <th>Категорія</th>
                                    <th>Статус</th>
                                    <th>Картинкa</th>
                                    <th>Взаємодія</th>
                                </tr>
                            </thead>
                            <tbody>
                            {products.length > 0 ? products.map((product, index) => (
                                        <tr key={product._id}>
                                            <td>{index + 1}</td>
                                            <td className=" table__cell">{product._id}</td>
                                            <td className=" table__cell">{product.name}</td>
                                            <td className=" table__cell">
                                                {!!product.sizes && <p className="table__cell-item">Розмір пляшки: {product.sizes} мл</p>}
                                                {!!product.discount && <p className="table__cell-item">Знижка на товар: {product.discount} </p>}
                                                {!!product.bottling && <p className="table__cell-item">Доступні мл: {product.quantityMl} мл</p>}
                                                {<p className="table__cell-item"><strong>Кількість: </strong> {product.quantity} шт\мл</p>}
                                                {product.currentPrice && <p className="table__cell-item "><strong>Актуальна ціна:</strong> {product.currentPrice} грн</p>}
                                                {!!product.previousPrice && <p className="table__cell-item">Попередня ціна: {product.previousPrice} грн</p>}
                                            </td>
                                            <td className=" table__cell"><p className="table__cell-item">{product.categories.name}</p></td>
                                            <td>
                                                <p>{product.enabled ? "Ввімкнений" : "Вимкнений" }</p>
                                                <Button variant="secondary" onClick={() => handleActive(product._id)}>
                                                    {product.enabled ? "Вимкнути" : "Ввімкнути" }
                                                </Button></td>
                                            <td className="table__cell"><div className={styles.product__img}><img src={product.imageFirst} alt="Image"/></div></td>
                                            <td>
                                                <Button variant="secondary" onClick={() => handleEdit(product._id)}>Редагувати</Button>{' '}
                                                <Button variant="danger" onClick={() => handleDelete(product._id)}>Видалити</Button>
                                            </td>
                                        </tr>
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan="5">Loading...</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        </div>
                    </div>
                    <Pagination perPage={perPage} startPage={startPage} setPage={setStartPage} productsQuantity={products.length} />

                                {/* Modal for editing category */}
                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>{selectedProduct ? "Редагування" : "Створення"} Продукту</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {selectedProduct && <Product categories={categories} brands={brands} create={false} edit={true} id={selectedProduct} onSubmit={handleCloseModal} close={handleCloseModal}/>}
                            {!selectedProduct && <Product categories={categories} brands={brands} create={true} edit={false} id={selectedProduct} onSubmit={handleCloseModal} close={handleCloseModal}/>}
                        </Modal.Body>
                    </Modal>
                    <Modal show={showDel} onHide={handleCloseDel}>
                        <Modal.Header closeButton>
                            <Modal.Title>Видалити</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Button variant="danger" onClick={() => handleDeleteAccess()}>Видалити</Button>
                        </Modal.Body>
                    </Modal>
                </Container>
            </section>
        </>
    )
}

export default Products;