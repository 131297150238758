import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useHttp } from '../../../hooks/http.hook';
import { useMessageError } from '../../../hooks/message.hook';
import BurgerMenu from '../../../components/BurgerMenu/BurgerMenu';
import "./Wishlist.scss";
import Container from '../../../components/Container/Container';
import { useNavigate } from 'react-router-dom';

const Wishlish = () => {
    const { request } = useHttp();
    const messageError = useMessageError();
    const navigate = useNavigate();
    const [wishlist, setWishlist] = useState([]);

    const fetchAll = async () => {
        try {
            const data = await request('customer/wishlist/getall', 'GET');
            setWishlist(data.data);
        } catch (error) {
            messageError(error);
        }
    };

    useEffect(() => {
        fetchAll();
    }, []);

    if(!wishlist || wishlist.length == 0 || !wishlist.products || wishlist.products.length == 0){
        return (
            <section>
                <BurgerMenu />
                <h2 style={{paddingTop:50+"px"}}>Всі Вподобані товари</h2>
                <p>Kількість: <span> 0</span></p>
            </section>
        );
    }
    const toProduct = (id) => {
        navigate(`/products/${id}`)
    }
    return (
        <section>
            <BurgerMenu />
            <Container>
                <h2 style={{paddingTop:50+"px"}}>Всі Вподобані товари</h2>
                <p>Kількість: <span>{!wishlist.products.length == 0 ? 0 : wishlist.products.length}</span></p>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Назва</th>
                            <th>Ціна</th>

                        </tr>
                    </thead>
                    <tbody>
                        {wishlist.products.length > 0 && wishlist.products.map((product, index) => (
                            <tr key={product._id}>
                                <td className={product.enabled?"":"bg__disabled"}>{index + 1}</td>
                                <td className={product.enabled?"":"bg__disabled"} onClick={() => toProduct(product._id)}>{product.name}</td>
                                <td className={product.enabled?"":"bg__disabled"}>{product.currentPrice}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </section>
    );
};

export default Wishlish;
