import React, { useContext, useEffect, useState } from "react";
import Burger from "./Burger/Burger"
import { AuthContext } from "../../context/AuthContext";
import MenuProducts from "./MenuProducts/MenuProducts";

const BurgerMenu = ({menu = []}) => {
    const [menuList, setMenuList]= useState([]);
    const [open, setOpen] = React.useState(false);
    const node = React.useRef();

    return (
        <>
            <div ref={node}>
                <Burger open={open} setOpen={setOpen}/>
                <MenuProducts open={open} setOpen={setOpen} menuList={menu.length > 0 ? menu : menuList}/>
            </div>
        </>
    );
};

export default BurgerMenu;