import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Form, Button } from 'react-bootstrap';
import * as Yup from 'yup';

const StatusesForm = ({ initialValues, onSubmit, edit, create, editValuess}) => {    
    const formik = useFormik({
        initialValues:create ? initialValues : editValuess,
        validationSchema: Yup.object({
            name: Yup.string().required("Обов'язкове поле"),
            type: Yup.string().required("Обов'язкове поле"),
            color: Yup.string(),
            active: Yup.boolean().required("Обов'язкове поле"),
        }),
        onSubmit: (values, { resetForm }) => {
            onSubmit(values);
            resetForm();
        },
    });
    useEffect(() => {
        create ? formik.setValues(initialValues) : formik.setValues(editValuess)
    }, [initialValues, editValuess]);

    return (
        <div>
            <Form onSubmit={formik.handleSubmit}>
                <Form.Group controlId="name">
                    <Form.Label>Назва</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter name"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <div className="text-danger">{formik.errors.name}</div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="color">
                    <Form.Label>Тип</Form.Label>
                    <Form.Control
                        type="color"
                        name="color"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.color}
                    />
                    {formik.touched.color && formik.errors.color ? (
                        <div className="text-danger">{formik.errors.color}</div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="type">
                    <Form.Label>Тип</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Введіть тип"
                        name="type"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.type}
                    />
                    {formik.touched.type && formik.errors.type ? (
                        <div className="text-danger">{formik.errors.type}</div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="active">
                    {/* <Form.Label>Status</Form.Label> */}
                    <Form.Check
                        type="switch"
                        id="active-checkbox"
                        label="Active"
                        name="active"
                        // onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.active}
                        onChange={(e) => formik.setFieldValue('active', e.target.checked)}
                    />
                    {formik.touched.active && formik.errors.active ? (
                        <div className="text-danger">{formik.errors.active}</div>
                    ) : null}
                </Form.Group>
                <Button variant="dark" type="submit">
                    {create && "Створити"} 
                    {edit && "Редагувати"}
                </Button>
            </Form>
        </div>
    );
};

export default StatusesForm;
