import React, { useState } from 'react';
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { useMessageSuccess } from '../../../../hooks/message.hook';
import { Formik, Form } from 'formik';
import { placeOrderNoAuth } from '../../../../http/ordersAPI';
import schema from './schema';
import MyTextInput from '../../../../components/MyForms/MyTextInput/MyTextInput';
import styles from './QuickOrder.module.scss';
import { Button } from 'react-bootstrap';
import CustomerDataInputs from '../../CustomerDataInputs/CustomerDataInputs';
import DeliveryDataInputs from '../../DeliveryDataInputs/DeliveryDataInputs';
import PaymentDataInputs from '../../PaymentDataInputs/PaymentDataInputs';
import { getUserIsLoadingSelector, getUserSelector } from '../../../../store/user/selectors';
import { cartTotalPriceAction, saveCartAction , saveLocalCartAction} from '../../../../store/cart/actions';
import { deleteCart } from '../../../../http/cartAPI';
import { Field } from 'formik'; 

// const QuickOrder = () => {
//   const [messageServer, setMessageServer] = useState(null);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [commentAvailible, setCommentAvailible] = useState(false);
//   const customerLoading = useSelector(getUserIsLoadingSelector);
//   const messageSuccess = useMessageSuccess();

//   return (
//     <Formik
//       initialValues={{
//         firstName:  '',
//         lastName: '',
//         phone: '+380',
//         email: '',
//         region: '',
//         city: '',
//         delivery: '',
//         address: '',
//         paymentInfo: '',
//         comment: '',
//       }}
//       validationSchema={schema}
//       onSubmit={(values, { setSubmitting }) => {
//         console.log("🚀 ~ QuickOrder ~ values:", values)
//         // const { delivery, region, city, address, ...ordersValue } = values;
//         //   const deliveryAddress = { delivery, region, city, address };

//         //   console.log("🚀 ~ QuickOrder ~ deliveryAddress:", deliveryAddress)
//         //   const status = 'processed';
//         //   placeOrderNoAuth(values)
//         //   .then(res => {
//         //     if (res.status === 200 || res.status === true || res.status === 201 ) {
//         //       // setMessageServer(<span style={{ color: 'green' }}>Замовлення успішно оформлене!</span>);
//         //       messageSuccess('Замовлення оформлене!')
//         //       navigate('orderAccepted');
//         //     }
//         //   })
//         //   .catch(err => {
//         //     setMessageServer(<span>{Object.values(err.data).join('')}</span>);
//         //   });

//           // .then(res => {
//           //   if (res.status === 200 || res.status === true || res.status === 201 ) {
//           //     messageSuccess('Замовлення оформлене!')
//           //     navigate('orderAccepted');
//           //   }
//           //   deleteCart().then(resCart => {
//           //     localStorage.setItem('cart', JSON.stringify({}));
//           //     dispatch(cartTotalPriceAction(0));
//           //     dispatch(saveCartAction({}));
//           //     dispatch(saveLocalCartAction({}));
//           //     return resCart;
//           //   });
//           // })
//         // setSubmitting(false);
//       }}
//     >
//       {formik => (
//         <Form>
          
//         <div className={styles.tabContainer}>
//         <CustomerDataInputs formik={formik}/>
//           <h3 className='checkout__title'>Доставка</h3>
//           <DeliveryDataInputs />
//           <hr />
//           <h3 className='checkout__title'>Оплата</h3>
//           <PaymentDataInputs />
//           <hr />
//           <div className={styles.addComment}>
//             {!commentAvailible && (
//               <span
//                 type='button'
//                 className={styles.comment}
//                 id='commentInvolver'
//                 onClick={() => {
//                   setCommentAvailible(true);
//                 }}
//               >
//                 Додати коментарій
//               </span>
//             )}
//             {commentAvailible && (
//               <div className={styles.textareaContainer}>
//                 <label className={styles.label}>Коментарій</label>
//                 <Field
//                   as='textarea'
//                   className={styles.textarea}
//                   name='comment'
//                   placeholder='Коментарій'
//                   rows={5}
//                 />
//               </div>
//             )}
//           </div>
//           <Button variant='dark' type='submit' >Оформити замовлення</Button>
//         </div>
//       </Form>
//       )}
//     </Formik>
//   );
// };
// export default QuickOrder;
const QuickOrder = () => {
  const [messageServer, setMessageServer] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [commentAvailable, setCommentAvailable] = useState(false);
  const customerLoading = useSelector(getUserIsLoadingSelector);
  const messageSuccess = useMessageSuccess();

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        phone: '+380',
        email: '',
        region: '',
        city: '',
        delivery: '',
        address: '',
        paymentInfo: '',
        comment: '',
      }}
      // validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        const { delivery, region, city, address, ...ordersValue } = values;
        const deliveryAddress = { delivery, region, city, address };
        const products = JSON.parse(localStorage.getItem('cart')) || {}
        // {'products':JSON.parse(localStorage.getItem('cart')) || {}};
        placeOrderNoAuth({ ...ordersValue, deliveryAddress, products })
          .then(res => {
            if (res.status === 200 || res.status === true || res.status === 201) {
              messageSuccess('Замовлення успішно оформлене!');
              navigate('orderAccepted');
                localStorage.setItem('cart', JSON.stringify({}));
                dispatch(cartTotalPriceAction(0));
                dispatch(saveCartAction({}));
                dispatch(saveLocalCartAction({}));
            }
          })
          .catch(err => {
            setMessageServer(<span>{Object.values(err.data).join('')}</span>);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {formik => (
        <Form>
          <div className={styles.tabContainer}>
            <CustomerDataInputs formik={formik} />
            <h3 className='checkout__title'>Доставка</h3>
            <DeliveryDataInputs />
            <hr />
            <h3 className='checkout__title'>Оплата</h3>
            <PaymentDataInputs />
            <hr />
            <div className={styles.addComment}>
              {!commentAvailable && (
                <span
                  type='button'
                  className={styles.comment}
                  id='commentInvolver'
                  onClick={() => setCommentAvailable(true)}
                >
                  Додати коментарій
                </span>
              )}
              {commentAvailable && (
                <div className={styles.textareaContainer}>
                  <label className={styles.label}>Коментарій</label>
                  <Field
                    as='textarea'
                    className={styles.textarea}
                    name='comment'
                    placeholder='Коментарій'
                    rows={5}
                  />
                </div>
              )}
            </div>
            <Button variant='dark' type='submit'>Оформити замовлення</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default QuickOrder;
