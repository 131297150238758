import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { replace } from '../../../../utils/func';
import styles from './ProductOrdersItem.module.scss';
import Decimal from 'decimal.js';
const PRODUCT_ROUTE = "products/item/"; 

const ProductOrdersItem = ({ product }) => {
  const calculatePrice = () => {
    let totalPrice;
    
    if (product.discount && product.discount > 0) {
      const discount = product.discount / 100;
      const sumDiscount = (product.cartQuantity * product.product.currentPrice * discount).toFixed(2);
      totalPrice = (product.cartQuantity * product.product.currentPrice - parseFloat(sumDiscount)).toFixed(2);
    } 
    else if (product.product.discount && product.product.discount !== 0) {
      const discount = product.product.discount / 100;
      const sumDiscount = (product.cartQuantity * product.product.currentPrice * discount).toFixed(2);
      totalPrice = (product.cartQuantity * product.product.currentPrice - parseFloat(sumDiscount)).toFixed(2);
    } else {
      totalPrice = (product.cartQuantity * product.product.currentPrice).toFixed(2);
    }
    totalPrice = new Decimal(totalPrice)
    .toDecimalPlaces(2)
    .toString();
    return totalPrice;
  };
  return (
    <div className={styles.productItem}>
      <div className={styles.productImage}>
        <NavLink to={`/${PRODUCT_ROUTE}${product.product._id}`}>
          <img src={product.product.imageFirst} width={100} height={100} alt='productImage' />
        </NavLink>
      </div>
      <div>
        <NavLink to={`/${PRODUCT_ROUTE}${product.product._id}`}>
          <div className={`${styles.bold} ${styles.productName}`}>
            {product.product.name}, {product.product.color}
          </div>
        </NavLink>
        <div className={styles.productPrice}>
          <div>
            Ціна: <span className={styles.bold}>{replace(product.product.currentPrice)} грн.</span>
          </div>
          <div>
            К-ть: {product.product.bottling 
            ? <span className={styles.bold}>{product.cartQuantity} мл.</span> 
            : <span className={styles.bold}>{product.cartQuantity} шт.</span>}
          </div>
          <div>
            Сума:{' '}
            <span className={styles.bold}>{calculatePrice()} грн.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductOrdersItem.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductOrdersItem;
