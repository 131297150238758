import React, { useContext, useEffect, useState } from 'react';
import { useHttp } from '../../../../hooks/http.hook';
import { useMessageError, useMessageSuccess } from '../../../../hooks/message.hook';
import { useNavigate, useParams } from 'react-router-dom';
import BlogForm from '../../../../components/Forms/BlogForm/BlogForm'; // Assuming BrandForm is a separate component
import axios from 'axios';
import { AuthContext } from '../../../../context/AuthContext';

const FormWrapper = () => {
    const { request } = useHttp();
    const auth = useContext(AuthContext);
    const messageError = useMessageError();
    const messageSuccess = useMessageSuccess();
    const navigate= useNavigate();
    const { id } = useParams();
    const [initialValues, setInitialValues] = useState({
        title: "",
        content: "",
        active: "",
        releaseDate: new Date().toISOString().split('T')[0]
    });

    const getById = async () => {
        try {
            const data = await request(`admin/blog/get/${id}`, "GET");
            setInitialValues(data.data);
            messageSuccess(data.message);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (id) getById();
    }, [id]);

    const onSubmit = async (values) => {
        try {
            const config = {}
            if(auth.isAuthenticated){
                config.headers = {"Authorization": `Bearer ${auth.token}`}
            }
            if (!id) {
                const data = await axios.post(`/api/admin/blog/create`, values, config)
                .then(res => {messageSuccess(res.data.message); navigate('/admin/blog');})
                .catch(er => messageError(er.response.data.message))
            }

            if (id) {
                const data = await axios.put(`/api/admin/blog/edit/${id}`, values, config)
                .then(res => {messageSuccess(res.data.message);navigate('/admin/blog');})
                .catch(er => messageError(er.response.data.message))
                messageSuccess(data.message);
            }
        } catch (e) {
            messageError(e);
        }
    };

    return (
        <>
            <BlogForm edit={id} initialValues={initialValues} onSubmit={onSubmit} />
        </>
    );
};

export default FormWrapper;
