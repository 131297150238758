export const getProductsSelector = state => state.products.allProducts;

export const getProductsFilteredSelector = state => state.products.filterProducts;

export const getOneProductSelector = state => state.products.product;

export const getProductsFilterSelector = state => state.products.productsFilter;

export const getProductsQuantitySelector = state => state.products.productsQuantity;

export const productsLoadingSelector = state => state.products.isLoading;

export const oneProductLoadingSelector = state => state.products.productLoading;
export const getAdminProductsSelector = state => state.products.allAdminProducts;
export const getAdminPanelProductsSelector = state => state.products.allAdminPanelProducts;
export const getAdminOneProductSelector = state => state.products.productAdmin;
export const getAdminPanelOneProductSelector = state => state.products.productAdminPanel;

// export const productsStyle = state => state.products.isGrid;
