import { getCartOperation, setCartTotalPriceOperation, updateCartOperation } from './cart/operations';
// import { getCatalogOperation } from './catalog/operations';
import { getAdminProductsOperation, getProductsOperation } from './products/operations';
import { wishListLoadingAction } from './wishList/actions';
import { getWishListOperation, updateWishListOperation } from './wishList/operations';

export const getDataOperation = (isAuth, admin) => dispatch => {
  const storageWishList = { products: JSON.parse(localStorage.getItem('wishList')) || [] };
  const localCart = JSON.parse(localStorage.getItem('cart'));

  // dispatch(getCatalogOperation());
  if(admin){
    dispatch(getAdminProductsOperation());
  }else{
    dispatch(getProductsOperation());
  }
  dispatch(wishListLoadingAction(true));
  if (isAuth) {
    dispatch(getWishListOperation(admin));
    dispatch(updateWishListOperation(admin, storageWishList));
    if (localCart) {
      dispatch(updateCartOperation(admin, localCart));
    } else {
      dispatch(getCartOperation(admin));
    }
  } 
  if (!isAuth) {
    dispatch(setCartTotalPriceOperation(localCart));
  }
};
