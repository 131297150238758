import React, { useState } from "react";
import { Button, Card } from 'react-bootstrap';
import "./CardItem.scss";
import Icons from "../../../components/Icons/Icons";
import axios from 'axios';
import { useMessageError} from '../../../hooks/message.hook';
import { Link, useNavigate } from "react-router-dom";
import { getWishListOperation as getWishList } from "../../../store/wishList/operations";
import { useDispatch} from "react-redux";
import AddToCartButton from "../../AddToCartButton/AddToCartButton";
import PriceBlock from "../../PriceBlock/PriceBlock";

const CardItem = ({ auth, item, wishProduct}) => {
    const messageError = useMessageError();
    const dispatch = useDispatch();
    const [iconType, setIconType]= useState('navHeart');
    const [iconColor, setIconColor]= useState(wishProduct[item._id]? "#000000" : "");
    const navigate = useNavigate();
    const admin = auth.permissions?.admin?.access;
    
    const onSubmit = async (id) => {      
        try {
            let mainUrl = "";
            const config = {}
            if(auth.isAuthenticated){
                config.headers = {"Authorization": `Bearer ${auth.token}`}
                mainUrl = admin ? "admin" : "customer"
            }
            
            axios.post(`/api/${mainUrl}/wishlist/${id}`, id, config)
            .then(res => {
                if(localStorage.getItem("wishlist")){
                    localStorage.removeItem("wishlist")
                }
                localStorage.setItem("wishlist", JSON.stringify(res.data.data));
                if(res.data.data.products.length>0){
                    wishProduct = {}
                    res.data.data.products.forEach((prod)=>{
                        prod._id == id ? setIconColor("#000000"): setIconColor("");
                        wishProduct[prod._id] = true;
                    })
                }
                dispatch(getWishList(admin));
            })
            .catch(er => messageError(er.response.data.message))
            
        } catch (e) {
            messageError(e);
        }
    };
    // Check if wishlist exists and has products
    const toProduct = (id) => {
        navigate(`/products/item/${id}`)
    }

    return (
        <Card className=" goods-tile">
            <Card.Img className="card_image cursor-pointer" onClick={() => toProduct(item._id)} variant="top" src={item.imageFirst} />
            {auth.isAuthenticated && <div key={item._id} style={{ cursor: 'pointer' }}>
                <Icons type={iconType} color="black" onClick={() => {onSubmit(item._id)}} filled={iconColor} width={25} height={25} />
            </div>}
            <Card.Body>
                <Card.Title className="cursor-pointer">
                    <Link to={`/products/item/${item._id}`}>{item.name}</Link>
                    </Card.Title>
                <div className="d-flex justify-content-between">
                    <PriceBlock className=" mb-3" previousPrice={item.previousPrice} currentPrice={item.currentPrice} />
                    <AddToCartButton admin={admin ? true : false} product={item} id={item._id} orderButton={false} currentPrice={item.currentPrice} />
                </div>
            </Card.Body>
        </Card>
    );
}

export default CardItem;
