import { Decimal } from 'decimal.js';
export const replace = num => {
  const str = num.toString();
  return str.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, `$1 `);
};

export const calculateTotalPrice = (cart) => {
  let totalPrice = 0;
  // cart?.products.map((p) => {
  //   if (p.currentPrice && p.cartQuantity) {
  //     totalPrice += p.cartQuantity * p.currentPrice;
  //   }
  //   else if(p.product.currentPrice && p.cartQuantity){
  //     totalPrice += (p.cartQuantity * p.product.currentPrice);
  //   } else {
  //   }
  // });
  console.log("🚀 ~ calculateTotalPrice ~ cart.products:", cart?.products)
  
  if(cart?.products?.length > 0)
  cart?.products.map((p) => {
    if (p.currentPrice && p.cartQuantity && p.product.length > 0) {
      if(p.discount && p.discount > 0) {
        const discount = (p.categories?.discount?.quantity / 100)
        const sumDiscount = replace((p.cartQuantity * p.currentPrice * discount).toFixed(2) )
        console.log("🚀 ~ cart?.products.map ~ sumDiscount:", sumDiscount)
        totalPrice += ((p.cartQuantity * p.currentPrice ) - sumDiscount);
      }else if(p.categories?.discount && p.categories?.discount?.quantity !== 0) {
        const discount = (p.categories?.discount?.quantity / 100)
        const sumDiscount = replace((p.cartQuantity * p.currentPrice * discount).toFixed(2) )
        console.log("🚀 ~ cart?.products.map ~ sumDiscount:", sumDiscount)
        totalPrice += ((p.cartQuantity * p.currentPrice ) - sumDiscount);
      } else {
         totalPrice += p.cartQuantity * p.currentPrice;
         console.log("🚀 ~ cart?.products.map ~ totalPrice:", totalPrice)
      }
    }
    else if(p.product?.currentPrice && p.cartQuantity){
      if(p.product.discount && p.product.discount > 0) {
        const discount = (p.product.categories?.discount?.quantity / 100);
        const sumDiscount = (p.cartQuantity * p.product.currentPrice * discount).toFixed(2); 
        totalPrice += ((p.cartQuantity * p.product.currentPrice ) - sumDiscount);
      }else if(p?.product?.categories?.discount && p?.product?.categories?.discount?.quantity !== 0) {
        const discount = (p.product.categories?.discount?.quantity / 100);
        const sumDiscount = (p.cartQuantity * p.product.currentPrice * discount).toFixed(2); 
        totalPrice += ((p.cartQuantity * p.product.currentPrice ) - sumDiscount);
      } else {
         totalPrice += p.cartQuantity * p.product.currentPrice;
      }
    } 
  });

  return   new Decimal(totalPrice)
  .toDecimalPlaces(2)
  .toString();
};

export const getDate = date => {
  const optionsDate = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  const dateStr = new Date(date).toLocaleString('ua', optionsDate);
  return dateStr;
};
