import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";
import { getAll } from "../../http/blogAPI";
import BlogCart from "../../components/Blog/BlogCart/BlogCart";
import styles from "./Blog.module.scss";
import Container from "../../components/Container/Container";
const Blog = () => {
    const [blogAll, setBlogAll] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        try {
            const allBlog = await getAll();
            if (allBlog.data.status) {
                setLoading(false);

                setBlogAll(allBlog.data.data);

            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    // if (loading) {
    //     return <Loader />;
    // }

    return (
        <>
            <Container>
                    <h1 className={styles.pageName}>Блог</h1>
                    <div className={styles.pageTitleWrapper}><h2>Головні події</h2></div>
                    {blogAll.length > 0 ? (
                        <div className={styles.carts}>
                            {blogAll.map((el, index) => {
                                return <BlogCart key={index} blogOne={el} />
                            })}
                        </div>
                    ) : (
                        <div>Подій немає</div>
                    )}
            </Container>
        </>
    );
};

export default Blog;
