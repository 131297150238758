import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { NavLink, useParams } from "react-router-dom";
import Container from "../../../components/Container/Container";
import styles from "./Product.module.scss";
import AddToCartButton from "../../../components/AddToCartButton/AddToCartButton";
import PriceBlock from "../../../components/PriceBlock/PriceBlock";
import { useSelector, useDispatch } from "react-redux";
import { getOneProductSelector } from "../../../store/products/selectors";
import { getOneProductOperation } from "../../../store/products/operations";
import Loader from "../../../components/Loader/Loader";
import ProductSizes from "../../../components/ProductSizes/ProductSizes";
import ProductDetails from "./ProductDetails/ProductDetails";

const Product = () => {
    const dispatch = useDispatch();
    const auth = useContext(AuthContext);
    const { id } = useParams();
    const product = useSelector(getOneProductSelector);
    const [mlOptions, setMlOptions]=useState([]);
    const [ml, setMl]=useState(1);
    const [characteristics, setCharacteristics]=useState([]);
    const choiceMl = (ml) => {
        setMl(ml)
    } 

    useEffect(() => {
        dispatch(getOneProductOperation(id));
    }, []); // Оновлення продуктy лише при монтуванні компоненту

    useEffect(() => {
        if(product.bottling){
            const options = [];
            for (let i = 1; i <= product.quantityMl; i += 1) {
                options.push(<li key={i} value={i} onClick={() => choiceMl(i)}>{i} мл</li>);
            }
            setMlOptions(options);
        }
        characters();
    }, [product.quantityMl,
        // product.categories.name,
        product.brand,
       product.manufacturerCountry,
       product.bottling,
       product.sizes]);
       const characters = () => {
        const arr = [
            {"Категорія": product?.categories?.name}
            , {"Бренд": product?.brand?.name}
            , {"Країна виробник":product.manufacturerCountry}
            , product.bottling ? {"Розлив": "Так"} : {"Емність":product.sizes}]
            setCharacteristics(arr)
    }
    if(Object.keys(product).length == 0){
        return ( 
            <Loader />
        )
    }

    return (
        <>
        <Container>
            <div className={styles.breadCrumbs}>
                <NavLink className={styles.crumbsLink} to='/'>
                    Головна
                </NavLink>
                <span className={styles.iconBreadcrumbs}>{}</span>
                <NavLink className={styles.crumbsLink} to={`/products/categories/${product.categories.title}`}>
                    {product.categories.name}
                </NavLink>
                <span className={styles.iconBreadcrumbs}>{}</span>
                <span className={styles.crumbs}>{product.name}</span>
            </div>
            <div className={styles.product__container}>
                <div className={styles.product__wrapper}>
                    <div className={styles.product__imageWrapper}>
                        <img  className={styles.product__image} src={product.imageFirst} alt="" sizes="" />
                    </div>
                </div>
                <div className={styles.product__wrapper}>
                    {!!product.discount &&  product.discount > 0 && <p className={styles.product__itemEnable}>
                         ? <span className={styles.product__disactive}>Знижка {product.discount}% від ціни</span> 
                    </p>}
                    <h2 className={styles.product__title}>{product.name}</h2>
                    <div>
                        {/* <div>
                            {!!product.seller && <p>Продавець: <span>{product.seller}</span></p>}
                        </div> */}
                        <div className={`${styles.product__block} mb-3`}>
                            {(product.productsGroups && product.productsGroups.products.length > 0 )&& <ProductSizes id={product._id} products={product.productsGroups.products}/>}
                            <div  className={styles.product__blockPrice}>
                                <PriceBlock className={` mb-3`} previousPrice={product.previousPrice} currentPrice={product.currentPrice} />
                            </div>
                            <p className={styles.product__itemEnable}>{product.quantity > 0 ? <span className={styles.product__active}>Є в наявностi</span> : <span className={styles.product__disactive}>Немає в наявностi</span>}</p>
                            {product.bottling && <AddToCartButton admin={auth.permissions?.admin?.access ? true : false} product={product} id={product._id} orderButton={false} currentPrice={product.currentPrice} ml={ml} />}
                            {!product.bottling && <AddToCartButton admin={auth.permissions?.admin?.access ? true : false} product={product} id={product._id} orderButton={false} currentPrice={product.currentPrice} />}
                        </div>
                        {/* {product.bottling && <div className={styles.group__container}>
                            <label htmlFor="">Виберіть к-ть мл</label>
                            <div className={styles.group__wrapperSelect}>
                                <p>{ml ? ml + " мл" : "Виберіть к-ть"}</p>
                                <details>
                                    <summary className={styles.group__arrow}></summary>
                                    <ul className={styles.group__select}>
                                        {mlOptions}
                                    </ul>
                                </details>
                            </div>
                        </div>} */}
                    </div>
                </div>
            </div>
            <ProductDetails
                    description={product.description}
                    characteristics={characteristics}/>
        </Container>
        </>
    );
}

export default Product;