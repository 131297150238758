import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import CardItem from "./Card/CardItem";
import "./ProductsCards.scss";
import { getWishListSelector } from "../../store/wishList/selectors";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";

const ProductsCards = ({ products, categoriesMenu }) => {
    const auth = useContext(AuthContext);
    const [productsCards, setProductsCards] = useState([]);
    let wishProduct = {};
    const storeWishlish = useSelector(getWishListSelector)

    useEffect(() => {
        if(storeWishlish && storeWishlish?.products?.length>0){
            storeWishlish.products.forEach((prod)=>{
                wishProduct[prod._id]=true;
            })
        }
        if(!storeWishlish && JSON.parse(localStorage.getItem("wishlist")) && JSON.parse(localStorage.getItem("wishlist")).products.length>0){
            JSON.parse(localStorage.getItem("wishlist")).products.forEach((prod)=>{
                wishProduct[prod._id]=true;
            })
        }
        if (products.length > 0) {
            const list_products = products.map((item, index) => {
                return (
                    <li key={item._id} className="catalog-grid__cell">
                        <CardItem auth={auth} item={item} wishProduct={wishProduct}/>
                    </li>
                )
            })
            setProductsCards(list_products)
        }
    }, [products])
    if(products.length == 0 || !categoriesMenu || categoriesMenu.length == 0){
        const loader = setTimeout(() => {
            return(
                <>
                    <Loader/>
                </>
            )
        }, 1000)
        clearTimeout(loader);
        return (
            <>
                <h1>Товари цієї категорії в дорозі...</h1>
            </>
        )
    }

    return (
        <>
            <ul className="catalog-grid">
                {products.length > 0 ? productsCards : <p>Товарів немає, ми вирішуємо цю проблему.</p>}
            </ul>
        </>
    );
}

export default ProductsCards;
