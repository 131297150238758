import { useCallback } from "react";
import { toast } from "react-toastify"; // Make sure this import is correct

export const useMessageError = () => {
  toast(); // Ensure that toast.configure is being called after importing toast
  return useCallback((text) => {
    if (text) {
      toast.error(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, []);
};

export const useMessageSuccess = () => {
  toast(); // Ensure that toast.configure is being called after importing toast
  return useCallback((text) => {
    if (text) {
      toast.success(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, []);
};