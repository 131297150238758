import { getFilterProducts } from '../../http/filtersAPI';
import { saveloaderAction } from '../loader/actions';
import { saveFiltersProductsAction,saveFiltersIsLoadingAction } from './actions';

export const filtersProductsOperation = (filters) => dispatch => {
  dispatch(saveFiltersIsLoadingAction(true));
  dispatch(saveloaderAction(true));
  getFilterProducts(filters)
  .then(res => {
    dispatch(saveFiltersProductsAction(res.data.data));
    dispatch(saveFiltersIsLoadingAction(false));
    dispatch(saveloaderAction(false));
  })
  .catch(err => {
    dispatch(saveloaderAction(false));
    dispatch(saveFiltersIsLoadingAction(false));
    return err;
  });

  
};
