/* eslint-disable dot-notation */
import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import PropTypes from 'prop-types';
import { Button} from 'react-bootstrap';
import { cartLoadingSelector, getCartSelector, getLocalCartSelector } from '../../store/cart/selectors';
import { saveModalOrderAction } from '../../store/modal/actions';
import { addProductToCartOperation } from '../../store/cart/operations';
import { cartTotalPriceAction, saveLocalCartAction } from '../../store/cart/actions';
import { calculateTotalPrice } from '../../utils/func';
import {AuthContext} from "../../context/AuthContext";
// import QuickOrder from '../QuickOrder/QuickOrder';

const AddToCartButton = ({admin, product, id, orderButton, currentPrice, modalHandler, ml}) => {
  console.log("🚀 ~ AddToCartButton ~ currentPrice:", currentPrice)
  // const [quickOrderOpen, setQuickOrderOpen] = useState(false);
  const [isCart, setIsCart] = useState(false);

  const dispatch = useDispatch();
  const isAuth = useContext(AuthContext).isAuthenticated;
  const cartLoading = useSelector(cartLoadingSelector);
  const cart = useSelector(getCartSelector);
  const localCart = useSelector(getLocalCartSelector);

  let idCartList = [];

  if (isAuth && !cartLoading && !!cart && !!cart.products) {
    idCartList = cart.products.map(prod => {
      return prod.product['_id'];
    });
  } else if (!isAuth && localCart && localCart.products && localCart.products.length >= 1) {
    idCartList = localCart.products.map(prod => {
      return prod.product;
    });
  }

  const openCart = () => {
    dispatch(saveModalOrderAction(true));
    // modalHandler();
  };

  const addToCart = () => {
    if (localCart && localCart.products) {
      const productCart = product.bottling 
      ? { products: [...localCart?.products, {bottling: product.bottling, cartQuantity: ml || 1, product: product, currentPrice }] }
      : { products: [...localCart?.products, { cartQuantity: 1, product: product, currentPrice }] };
      localStorage.setItem('cart', JSON.stringify(productCart));
      // console.log("🚀 ~ addToCart ~ productCart:", productCart)

      const totalPrice = calculateTotalPrice(productCart, false);
      dispatch(cartTotalPriceAction(totalPrice));

      dispatch(saveLocalCartAction(productCart));
    } else {
      const productCart = product.bottling 
      ? { products: [{ bottling: product.bottling, cartQuantity: ml || 1, product: product, currentPrice }] } 
      : { products: [{ cartQuantity: 1, product: product, currentPrice }] } ;
      localStorage.setItem('cart', JSON.stringify(productCart));
      // console.log("🚀 ~ addToCart ~ productCart:", productCart)

      const totalPrice = calculateTotalPrice(productCart, false);
      dispatch(cartTotalPriceAction(totalPrice));

      dispatch(saveLocalCartAction(productCart));
    }

    if (isAuth) {
      dispatch(addProductToCartOperation(admin, id));
      // console.log("🚀 ~ addToCart ~ id:", id)

    }

    setIsCart(true);
  };

  return (
    <>
      {!product.bottling &&<div>
        {idCartList.includes(id) || isCart ? (
          <Button onClick={openCart} variant='dark' disabled={!product.bottling && product.quantity == 0}>В корзинi</Button>
        ) : (
          <Button onClick={addToCart} variant='dark' disabled={!product.bottling && product.quantity == 0}>Додати в кошик</Button>
        )}
      </div>}
      {product.bottling &&<div>
        {idCartList.includes(id) || isCart ? (
          <Button onClick={openCart} variant='dark' disabled={product.bottling && product.quantityMl == 0 }>В корзинi</Button>
        ) : (
          <Button onClick={addToCart} variant='dark' disabled={product.bottling && product.quantityMl == 0 }>Додати в кошик</Button>
        )}
      </div>}
      {/* {orderButton && (
        <Button
          variant='dark'
          onClick={() => setQuickOrderOpen(true)}
        >Додати в кошик</Button>
      )} */}

      {/* {quickOrderOpen && (
        <QuickOrder product={product} quickOrderOpen={quickOrderOpen} setQuickOrderOpen={setQuickOrderOpen} />
      )} */}
    </>
  );
};

export default AddToCartButton;
