import React, { useState, useEffect, useContext } from 'react';
import { Table } from 'react-bootstrap';
import BurgerMenu from '../../../components/BurgerMenu/BurgerMenu';
import {getWishListOperation as getWishList} from "../../../store/wishList/operations";
import {getWishListSelector, wishListLoadingSelector} from "../../../store/wishList/selectors";
import "./Wishlist.scss";
import Container from '../../../components/Container/Container';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../../context/AuthContext';

const Wishlish = () => {
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();
    const wishlist = useSelector(getWishListSelector);
    const isLoading = useSelector(wishListLoadingSelector);

    useEffect(() => {
        dispatch(getWishList(auth.permissions?.admin?.access));
    }, []);

    if (isLoading) {
        return <p>Loading...</p>;
    }
    
    if(!wishlist || wishlist.length == 0 || !wishlist.products || wishlist.products.length == 0){
        return (
            <section>
                <BurgerMenu />
                <h2 style={{paddingTop:50+"px"}}>Всі Вподобані товари</h2>
                <p>Kількість: <span> 0</span></p>
            </section>
        );
    }
    return (
        <section>
            <BurgerMenu />
            <Container>
                <h2 style={{paddingTop:50+"px"}}>Всі вподобані товари</h2>
                <p>Kількість: <span>{wishlist.products.length == 0 ? 0 : wishlist.products.length}</span></p>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Назва</th>
                            <th>Ціна</th>

                        </tr>
                    </thead>
                    <tbody>
                        {wishlist.products.length > 0 && wishlist.products.map((product, index) => (
                            <tr key={product._id}>
                                <td className={product.enabled?"":"bg__disabled"}>{index + 1}</td>
                                <td className={product.enabled?"":"bg__disabled"}>{product.name}</td>
                                <td className={product.enabled?"":"bg__disabled"}>{product.currentPrice}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </section>
    );
};

export default Wishlish;
