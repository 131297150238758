import { $host } from './index';


// @route   GET /filters/:type
// @desc    GET existing filters by "type" field
// @access  Public
export const getFilterProducts = async filters => {
  const res = await $host.get(`filters/products`, {params: {...filters}}).catch(err => {
    throw err.response;
  });
  return res;
};
