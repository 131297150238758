import { useState, useCallback, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { saveCartAction, saveLocalCartAction } from "../store/cart/actions";
import { saveOrderAction, saveOrdersAction } from "../store/orders/actions";
import { saveUserAction } from "../store/user/actions";

const storageName = "sessionData";

export const useAuth = () => {
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [permissions, setPermissions] = useState(null)
  const [userEmail, setUserEmail] = useState(null)
  const dispatch = useDispatch()

  const login = useCallback((jwtToken, id, permissions, userEmail) => {
    setToken(jwtToken);
    setUserId(id);
    setPermissions(permissions);
    setUserEmail(userEmail);


    localStorage.setItem(
      storageName,
      JSON.stringify({
        userId: id,
        userEmail: userEmail,
        token: jwtToken,
        permissions: permissions
      })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setUserEmail(null);
    setPermissions(null);
    localStorage.removeItem(storageName);
    localStorage.removeItem('wishlist');
    localStorage.removeItem('cart');
    dispatch(saveCartAction({}))
    dispatch(saveLocalCartAction({}))
    dispatch(saveUserAction({}))
    dispatch(saveOrdersAction([]))
    dispatch(saveOrderAction({}))
    
  }, []);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(storageName));
    if (data && data.token) {
      login(data.token, data.userId,data.permissions, data.userEmail );
    }
  }, [login]);

  return { login, logout, token, userId, userEmail, permissions};
};
