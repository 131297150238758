import React, { useState, useEffect } from 'react';
import { Table, Modal, Button } from 'react-bootstrap';
import { useHttp } from '../../../hooks/http.hook';
import { useMessageError, useMessageSuccess } from '../../../hooks/message.hook';
import FormWrapper from './FormWrapper/FormWrapper';
import Container from '../../../components/Container/Container';
import BurgerMenu from '../../../components/BurgerMenu/BurgerMenu';
import { useNavigate } from 'react-router-dom';
import parse from "html-react-parser";
import "./Blog.scss";

const Blog = () => {
    const { request } = useHttp();
    const messageError = useMessageError();
    const messageSuccess = useMessageSuccess();
    const [showModal, setShowModal] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const [delId, setDelId] = useState();
    const [selected, setSelected] = useState(null);
    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate();

    const getFetch = async () => {
        try {
            const data = await request('admin/blog/getall', 'GET');
            setBlogs(data.data);
        } catch (error) {
            messageError(error);
        }
    };

    useEffect(() => {
        getFetch();
    }, []);

    const handleDelete =  (id) => {
        setShowDel(true);
        setDelId(id)
    };
    const handleDeleteAccess = async () => {
        setShowDel(false);
        try {
            const data = await request(`admin/blog/delete/${delId}`, 'DELETE');
            messageSuccess(data.message);
            setDelId("")
            getFetch();
        } catch (error) {
            messageError(error);
        }
    };

    const handleCreate = () => {
        navigate(`form`);
        setShowModal(true);
        setSelected(null);
    };
    const handleEdit = (id) => {
        navigate(`form/${id}`);
        setSelected(id);
        setShowModal(true);
    };
    // const handleCloseModal = (success = false) => {
    //     setShowModal(false);
    //     if(success) getFetch();
    // };
    const handleCloseDel = () => {
        setShowDel(false);
        setDelId("")
    }

    return (
        <>
            <Container>
                <BurgerMenu />
                <h2>Всі Новини</h2>
                <p>Kількість: <span>{blogs.length}</span></p>
                <div className='d-flex justify-content-end'>
                    <Button variant="dark" className='mb-3' onClick={() => handleCreate()}>Створити</Button>{' '}
                </div>
                <div className='table__wrapper'>
                    <Table striped bordered hover className='table__main'>
                        <colgroup>
                            <col className="low" />
                            <col className="middle" />
                            <col className="middle" />
                            <col className="middle" />
                            <col className="middle" />
                        </colgroup>
                        <thead className='table__head'>
                            <tr >
                                <th >#</th>
                                <th >Назва</th>
                                <th >Картинка</th>
                                <th >Статус</th>
                                <th >Взаємодія</th>
                            </tr>
                        </thead>
                        <tbody className='table__body'>
                            {blogs.length > 0 && blogs.map((blog, index) => (
                                <tr key={blog._id}>
                                    <td>{index + 1}</td>
                                    <td className='table__cell'><p className='table__cell--item'>{blog.title}</p></td>
                                    <td><div className="product__img"><img src={blog.imageUrl} alt="Image" /></div></td>
                                    <td>{blog.active ? "Ввімкнений" : "Вимкнений"}</td>
                                    <td>
                                        <div className='d-flex flex-wrap gap-1'>
                                            <Button variant="secondary" onClick={() => handleEdit(blog._id)}>Редагування</Button>{' '}
                                            <Button variant="danger" onClick={() => handleDelete(blog._id)}>Видалити</Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                            {/* Modal for editing blog */}
                <Modal show={showDel} onHide={handleCloseDel}>
                    <Modal.Header closeButton>
                        <Modal.Title>Видалити</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Button variant="danger" onClick={() => handleDeleteAccess()}>Видалити</Button>
                    </Modal.Body>
                </Modal>
            </Container>
        </>
    );
};

export default Blog;
