import Main from "./components/main/Main"
import './App.scss';
import Footer from "./components/footer/Footer";
import { useAuth } from "./hooks/auth.hook";
import Header from "./components/header/Header";
import { AuthContext } from "./context/AuthContext";
import Popup from "./components/Popup/Popup";

function App() {   
  const { token, userId, login, logout, userEmail, permissions} = useAuth();
      
  return (
    <div className="App container_app">
      <AuthContext.Provider
        value={{
        token,
        login,
        logout,
        userId,
        isAuthenticated:!!token,
        userEmail,
        permissions
        }}
      > 
        <Popup />
        <Header/>
        <Main />
        <Footer />
      </AuthContext.Provider>
    </div>
  );
}

export default App;
