import React, { useState } from 'react';
import styles from './ProductDetails.module.scss';
const ProductDetails = ({ description, characteristics }) => {
  const [showDescription, setShowDescription] = useState(true);

  const toggleTab = () => {
    setShowDescription(!showDescription);
  };

  return (
    <div>
      <div className={styles.tabs}>
        <button className={`${styles.tab__button} ${showDescription ? `${styles.active}` : ''}`} onClick={toggleTab}>Опис</button>
        {characteristics.length > 0 && <button className={`${styles.tab__button} ${!showDescription ? `${styles.active}` : ''}`} onClick={toggleTab}>Характеристики</button>}
      </div>
      <div className={styles.tab__content}>
        {showDescription ? <p>{description}</p> 
        : <ul> {characteristics.map((item, index) => <li key={index}><p><strong>{Object.keys(item)} : </strong><span>{Object.values(item)}</span></p></li>)}</ul>}
      </div>
    </div>
  );
};

export default ProductDetails;