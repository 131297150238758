import React, { useEffect, useState } from "react";
import "./Account.scss";
import BurgerMenu from "../../../components/BurgerMenu/BurgerMenu";
import Profile from "../../../components/Profile/Profile";
import { editAdmin } from "../../../http/userAPI";
import Container from "../../../components/Container/Container";
import { useMessageSuccess } from "../../../hooks/message.hook";
import { getAdminOperation as getAdmin } from "../../../store/user/operations";
import { getUserSelector } from "../../../store/user/selectors";
import { useSelector, useDispatch } from "react-redux";
const Account = () =>  {
  const messageSuccess = useMessageSuccess();  
  const dispatch = useDispatch();
  const user = useSelector(getUserSelector); 

  const editSelfInfo = (values) => editAdmin(values).then(res => {
    if(res.status) {
      messageSuccess(res.data);
      dispatch(getAdmin())
    }
  })
  useEffect(() => {
    dispatch(getAdmin())
  },[])
  
  return (
    <>
    <Container>
      <section className="form__container">
        <h1 className="pageName">Account</h1>
        <BurgerMenu/>
        <Profile user={user} editSelfInfo={editSelfInfo}/>
      </section>
    </Container>
    </>
  );
}

export default Account;