import {
  SET_STATUS,
  SET_ALL_STATUSES,
  SET_STATUS_LOADING
} from './types';

const initialState = {
  allStatuses: [],
  status: {},
  statusLoading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATUS: {
      return { ...state, status: action.payload };
    }
    case SET_ALL_STATUSES: {
      return { ...state, allStatuses: action.payload };
    }
    case SET_STATUS_LOADING: {
      return { ...state, statusLoading: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
