/* eslint-disable react/no-danger */
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import schema from './schema';
import { updateOrder } from '../../../../http/ordersAPI';
import DeliveryDataInputs from '../../../../pages/Orders/DeliveryDataInputs/DeliveryDataInputs';
import PaymentDataInputs from '../../../../pages/Orders/PaymentDataInputs/PaymentDataInputs';
import CustomerDataInputs from '../../../../pages/Orders/CustomerDataInputs/CustomerDataInputs';
import styles from './SpecifyOrder.module.scss';
import { orderOpenOperation } from '../../../../store/modal/operations';
import { Button } from 'react-bootstrap';

const SpecifyOrder = ({ orderId, order, setspecifyOrderState, setOrderState }) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      <Formik
        initialValues={{
          firstName: order.firstName || '',
          lastName: order.lastName || '',
          phone: order.phone || '+380',
          email: order.email || '',
          region: order.deliveryAddress.region || '',
          city: order.deliveryAddress.city || '',
          delivery: order.deliveryAddress.delivery || '',
          address: order.deliveryAddress.address || '',
          paymentInfo: order.paymentInfo || '',
          comment: order.comment || '',
        }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          const { delivery, region, city, address, ...ordersValue } = values;
          const deliveryAddress = { delivery, region, city, address };

          const letterSubject = 'Ваше замовлення уточнено!';
          const status = 'processed';

          updateOrder(orderId, {
            ...ordersValue,
            deliveryAddress,
            letterSubject,
            email: order.email,
            status,
          })
            .then(res => {
              if (res.status === 200) {
                dispatch(orderOpenOperation('Замовлення уточнено!'));
                setspecifyOrderState(false);
                setOrderState(res.data.order);
              }
            })
            .catch(err => {
              const message = Object.values(err.data).join('');
              dispatch(orderOpenOperation(message, true));
            });

          setSubmitting(false);
        }}
      >
        <Form>
          <CustomerDataInputs/>
          <DeliveryDataInputs region={order.deliveryAddress.region}/>
          <PaymentDataInputs />

          <div className={styles.textareaContainer}>
            <label className={styles.label}>Коментарій</label>
            <Field
              as='textarea'
              className={styles.textarea}
              name='comment'
              placeholder='Текст'
              rows={5}
            />
          </div>
          <Button variant='dark'>Уточнити замовлення</Button>
        </Form>
      </Formik>
    </div>
  );
};

SpecifyOrder.propTypes = {
  orderId: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
  setspecifyOrderState: PropTypes.func.isRequired,
  setOrderState: PropTypes.func.isRequired,
};

export default SpecifyOrder;
