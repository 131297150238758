import { combineReducers } from 'redux';
import productsReducer from './products/reducer';
import wishListReducer from "./wishList/reducer";
import userReducer from "./user/reducer";
import statusesReducer from "./statuses/reducer";
import ordersReducer from "./orders/reducer";
import modalReducer from "./modal/reducer";
import cartReducer from "./cart/reducer";
import loaderReducer from "./loader/reducer";
import categoriesReducer from "./categories/reducer";
import filtersReducer from "./filters/reducer";

const reducer = combineReducers({
  user: userReducer,
  products: productsReducer,
  statuses: statusesReducer,
  orders: ordersReducer,
  modal: modalReducer,
  cart: cartReducer,
  wishList: wishListReducer,
  loader: loaderReducer,
  categories: categoriesReducer,
  filters: filtersReducer
});

export default reducer;
