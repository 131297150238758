import React, { useEffect, useState } from "react";
import styles from "./Menu.module.scss";
import DoubleSlider from "../../DoubleSlider/DoubleSlider";

const Menu = ({ open, filterProducts, filter, selected, ordersFilter}) => {
  const [sizes, setSizes] = useState([]);
  
  useEffect(()=>{
      if(Object.keys(filterProducts).length>0){
          const sizesOpt = filterProducts.filters.sizes.map((item, index) => {
              return <option key={index} value={item}>{item}мл</option>
          })
          setSizes(sizesOpt)
      }
  },[filterProducts]);

  return(
    <nav className={`${styles.menu} ${open ? `${styles.open}` : ''}`}>
      <div className={`${styles.menu__wrapper}`}>
        <div>
          <select name="sizes" id="sizes" value={ ordersFilter?.filter?.sizes || 0} onChange={(e) =>{filter("sizes", e.target.value)}}>
            <option value={0}>Об'єм</option>
            {sizes}
          </select>
        </div>
        <DoubleSlider filterProducts={filterProducts} filter={filter} ordersFilter={ordersFilter}/>
      </div>
    </nav>
  )
}
  
export default Menu;