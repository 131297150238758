import React, { useState, useEffect } from 'react';
import { useHttp } from '../../../../hooks/http.hook';
import { useMessageError } from '../../../../hooks/message.hook';
import styles from "./ChangeRole.module.scss";

const ChangeRole = ({user, close}) => {
    const { request } = useHttp();
    const messageError = useMessageError();
    const [userData, setUserData] = useState();
    const [choiceUser, setChoiceUser] = useState('');

    const fetchData = async () => {
        try {
            const rolesData = await request('admin/roles/get', 'GET');

            if (rolesData.data) {
                const options = rolesData.data.map(role => (
                    <li key={role._id} value={role._id} onClick={() => changeRole( role._id)}>
                        {role.name}
                    </li>
                ));
                setUserData( options );
            }
        } catch (error) {
            messageError(error);
        }
    };

    const changeRole = async (userRoleId) => {
        try {
                await request(`admin/users/changerole/${user._id}`, 'PUT', { userRoleId: userRoleId });
                close()
        } catch (error) {
            messageError(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (

        <div className={styles.group__container}>
            <div className={styles.group__wrapperSelect}>
                <p>{user.userRoleId.name}</p>
                <details>
                    <summary className={styles.group__arrow}></summary>
                    <ul className={styles.group__select}>
                        {userData}
                    </ul>
                </details>
            </div>
        </div>
    
    );
};

export default ChangeRole;
