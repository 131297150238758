import React, { useEffect, useRef, useState } from 'react';
import "./BlogForm.scss";
import CKEditor from "react-ckeditor-component";
import { useFormik } from 'formik';
import { Form, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import Container from '../../Container/Container';

const BlogForm = ({edit, initialValues, onSubmit}) => {
    const [files, setFiles] = useState([]);
    const [text, setText] = useState("");
    // const editorRef = useRef(null); // Create a ref for the CKEditor component
    const heightVar = window.innerHeight - 190;
    const toolbarConfig = {
      height: heightVar,
      toolbar: 'Full',
      allowedContent: true,
      startupFocus: true,
    };

  const formik = useFormik({
    initialValues:initialValues,
    validationSchema: Yup.object({
        title: Yup.string().required("Обов'язкове поле"),
        imageUrls: Yup.string(),
        imageUrl: Yup.string(),
        imageUrlChange: Yup.string(),
        active: Yup.boolean().required("Обов'язкове поле"),
        releaseDate: Yup.string().required("Обов'язкове поле"),
    }),
    onSubmit: (values, { resetForm }) => {
        const formData = new FormData();
        formData.append('active', values.active);
        formData.append('title', values.title);
        formData.append('imageUrl', values.imageUrl);
        formData.append('releaseDate', values.releaseDate);
        formData.append('file', files);
        formData.append('content', text);
        onSubmit(formData);
        resetForm();
    },
  });
  const handleText = (e) => {
    const newContent = e.editor.getData();
    setText(newContent)
  }

  useEffect(() => {
    formik.setValues(initialValues);
    setText(initialValues.content);
  }, [initialValues]);

    return (
        <Container>

          {/* <input type='file' name='imgBlog' onChange={(e)=> setFiles(e.target.files)} accept='image/*'  multiple/> */}

          <Form  onSubmit={formik.handleSubmit}>
            {formik.values.imageUrl && <div className='product__img'>
                <p>Стара картинка новини</p>
                <img src={formik.values.imageUrl} alt="Стара картинка" /></div>}
                <div className='d-flex justify-content-center mb-3 gap-3 flex-wrap'>
                  <Form.Group controlId="imageUrlChange">
                    <Form.Label>Завантажити картинку новини</Form.Label>
                    <Form.Control
                      type="file"
                      name={`imageUrlChange`}
                      onChange={event => {
                          // Встановлюємо файл як значення форми
                          formik.setFieldValue(`imageUrlChange`, "file");
                          setFiles(event.target.files[0])
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.imageUrlChange && formik.errors.imageUrlChange && (
                        <div className="text-danger">
                        {formik.errors.imageUrlChange}
                        </div>
                    )}
                    {formik.values.imageUrl && (
                        <div style={{width:100+"%"}}>
                        </div>
                    )}
                  </Form.Group>
                  <Form.Group controlId="active">
                    <Form.Label>Active</Form.Label>
                    <Form.Check
                        type="switch"
                        id="active-checkbox"
                        name="active"
                        // onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.active}
                        onChange={(e) => formik.setFieldValue('active', e.target.checked)}
                    />
                    {formik.touched.active && formik.errors.active ? (
                        <div className="text-danger">{formik.errors.active}</div>
                    ) : null}
                  </Form.Group>
                  <Form.Group controlId="releaseDate">
                    <Form.Label>Дата публікації</Form.Label>
                    <Form.Control
                        type="date"
                        placeholder="Enter releaseDate"
                        name="releaseDate"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.releaseDate}
                    />
                    {formik.touched.releaseDate && formik.errors.releaseDate ? (
                        <div className="text-danger">{formik.errors.releaseDate}</div>
                    ) : null}
                  </Form.Group>
                </div>
                <Form.Group controlId="title" className='mb-3'>
                  <Form.Label>Назва або заголовок</Form.Label>
                  <Form.Control
                      type="text"
                      placeholder="Enter title"
                      name="title"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                  />
                  {formik.touched.title && formik.errors.title ? (
                      <div className="text-danger">{formik.errors.title}</div>
                  ) : null}
                </Form.Group>
                <Form.Group controlId="content">
                  <div className='editor'>
                    <CKEditor 
                      activeClass="p10"
                      config={toolbarConfig}
                      content={text}
                      events={{
                        "blur": () => {},
                        "afterPaste": () => {},
                        "change": handleText,
                      }}
                    />
                  </div>
                </Form.Group>
                <Button variant="dark" type="submit" className='mb-3 mt-3'>
                {edit ? "Редагування новини" : "Створити новину"} 
                </Button>
            </Form>
        </Container>
    );
};


export default BlogForm;
