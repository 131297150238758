/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
import {
  addProductToCart,
  decreaseCartProductQuantity,
  deleteProductFromCart,
  getCart,
  updateCart,
} from '../../http/cartAPI';
import { calculateTotalPrice } from '../../utils/func';
import { saveModalOrderAction } from '../modal/actions';
import { cartLoadingAction, cartTotalPriceAction, saveCartAction, saveLocalCartAction } from './actions';

// Получить всю корзину
export const getCartOperation = (admin) => dispatch => {
  dispatch(cartLoadingAction(true));
  getCart(admin).then(res => {
    dispatch(saveCartAction(res.data));
    dispatch(cartLoadingAction(false));
    if (res.data) {
      // const databaseCart = res.data.products.map(productCart => {
      //   return { cartQuantity: productCart.cartQuantity, product: productCart.product['_id'], currentPrice: productCart.product.currentPrice };
      // });
      // localStorage.setItem('cart', JSON.stringify({ products: [...databaseCart] }));
      localStorage.setItem('cart', JSON.stringify( res.data ));
      dispatch(setCartTotalPriceOperation(res.data));
    } else {
      localStorage.setItem('cart', JSON.stringify({}));
    }
  });
};

// Уменьшить к-во товара в корзине
export const decreaseCartProductQuantityOperation = (admin, id) => dispatch => {
  decreaseCartProductQuantity(admin, id).then(res => {
    dispatch(saveCartAction(res.data));
  });
};

// Удалить товар из корзины
export const deleteProductFromCartOperation = (admin, id, cart) => dispatch => {
  deleteProductFromCart(admin, id).then(res => {
    if (cart.products.length === 1) {
      dispatch(saveCartAction(null));
      dispatch(saveModalOrderAction(false));
    } else {
      dispatch(saveCartAction(res.data));
    }
  });
};

// Добавить товар в корзину
export const addProductToCartOperation = (admin, id) => dispatch => {
  addProductToCart(admin, id).then(res => {
    dispatch(saveCartAction(res.data));

  });
};

export const updateCartOperation = (admin, products) => dispatch => {
  dispatch(cartLoadingAction(true));
  updateCart(admin, products).then(res => {
    dispatch(saveCartAction(res.data));
    dispatch(cartLoadingAction(false));
    dispatch(setCartTotalPriceOperation(res.data));
  });
};

export const changeLocalCartOperation = (id, method) => dispatch => {
  const localCart = JSON.parse(localStorage.getItem('cart'));
  let newLocalCart
  if(localCart.products){
    newLocalCart = localCart.products.map(product => {
  
      if (product?.product._id === id) {
        if (method === 'decrease') {
          product.cartQuantity -= 1;
        } else if (method === 'add') {
          product.cartQuantity += 1;
        } else if (method === 'delete') {
          return null;
        }
      }
      return product;
    });
  }
  if(localCart && localCart.length>0){
    newLocalCart = localCart.map(product => {
  
      if (product?.product._id === id) {
        if (method === 'decrease') {
          product.cartQuantity -= 1;
        } else if (method === 'add') {
          product.cartQuantity += 1;
        } else if (method === 'delete') {
          return null;
        }
      }
      return product;
    });
  }
  const filtered = newLocalCart.filter(el => {
    return el != null;
  });

  localStorage.setItem('cart', JSON.stringify( [...filtered] ));

  const totalPrice = calculateTotalPrice({ products: [...filtered] });

  dispatch(cartTotalPriceAction(totalPrice));

  dispatch(saveLocalCartAction( [...filtered] ));
};

export const setCartTotalPriceOperation = products => dispatch => {
  
  const totalPrice = calculateTotalPrice(products);
  dispatch(cartTotalPriceAction(totalPrice));
};
