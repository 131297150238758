import { getAll, getById } from '../../http/statusesAPI';
import {
  saveStatusAction,
  saveStatusesAction,
  statusesLoadingAction
} from './actions';

export const getStatusesOperation = () => dispatch => {
  dispatch(statusesLoadingAction(true));
  getAll().then(res => {
    dispatch(saveStatusesAction(res.data.data));
    dispatch(statusesLoadingAction(false));
  });
};

export const getOneStatusOperation = id => dispatch => {
  dispatch(statusesLoadingAction(true));
  getById(id).then(res => {
    dispatch(saveStatusAction(res.data.data));
    dispatch(statusesLoadingAction(false));
  });
};