import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useHttp } from '../../../hooks/http.hook';
import { useMessageError } from '../../../hooks/message.hook';
import BurgerMenu from '../../../components/BurgerMenu/BurgerMenu';
import Container from "../../../components/Container/Container";
import Icons from '../../../components/Icons/Icons';
import styles from "./Users.module.scss";
import ChangeRole from './ChangeRole/ChangeRole';

const Users = () => {
    const { request } = useHttp();
    const messageError = useMessageError();
    const [userData, setUserData] = useState({ users: [], rolesOptions: [] });
    const [choiceUser, setChoiceUser] = useState('');
    const [showModal, setShowModal] = useState(false);

    const fetchData = async () => {
        try {
            const usersData = await request('admin/users/getall', 'GET');

            setUserData({ users: usersData.data });
        } catch (error) {
            messageError(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const handleCloseModal = (success) => {
        setShowModal(false);
        fetchData();
    };

    return (
        <Container>
            <section>
                <BurgerMenu />
                <h2 style={{ paddingTop: 50 + "px" }}>Всі Користувачі</h2>
                <p>Kількість: <span>{userData.users.length}</span></p>
                <div className='table__wrapper'>
                    <Table striped bordered hover className='table__main'>
                        <colgroup>
                            <col className="low" />
                            <col className="middle" />
                            <col className="middle" />
                            <col className="middle" />
                            <col className="middle" />
                        </colgroup>
                        <thead className='table__head'>
                            <tr>
                                <th>#</th>
                                <th>ПІ</th>
                                <th>Дані</th>
                                <th>Адреса</th>
                                <th>Адреса доставки</th>
                                <th>Ролі</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userData.users.map((user, index) => (
                                <tr key={user._id}>
                                    <td>{index + 1}</td>
                                    <td><p>{user.firstName} {user.lastName}</p></td>
                                    <td>
                                        <p>{user.phone || "Не вказано"}</p>
                                        <p>{user.email || "Не вказано"}</p>
                                    </td>
                                    <td>{user.adress && (
                                        <div>
                                            <p>{user.adress.country || "Не вказано"}</p>
                                            <p>{user.adress.street1 || "Не вказано"}</p>
                                            <p>{user.adress.street2 || "Не вказано"}</p>
                                            <p>{user.adress.city || "Не вказано"}</p>
                                            <p>{user.adress.state || "Не вказано"}</p>
                                            <p>{user.adress.zip || "Не вказано"}</p>
                                        </div>
                                    )}</td>
                                    <td>{user.shippingAddress && (
                                        <div>
                                            <p>{user.shippingAddress.street1 || "Не вказано"}</p>
                                            <p>{user.shippingAddress.street2 || "Не вказано"}</p>
                                            <p>{user.shippingAddress.city || "Не вказано"}</p>
                                            <p>{user.shippingAddress.state || "Не вказано"}</p>
                                            <p>{user.shippingAddress.zip || "Не вказано"}</p>
                                        </div>
                                    )}</td>
                                    <td>
                                        {user.userRoleId && (
                                            <div>
                                                    <>
                                                        <Icons
                                                            className={styles.iconChange}
                                                            type='edit'
                                                            color='#999999'
                                                            filled
                                                            width={20}
                                                            height={20}
                                                            onClick={() =>{setShowModal(true); setChoiceUser(user); }}
                                                        />
                                                        <div className={styles.statusText}>{user.userRoleId.name}</div>
                                                    </>
                                              
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </section>
            <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Змінити роль</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                         <ChangeRole user={choiceUser} onSubmit={handleCloseModal} close={handleCloseModal}/>
                    </Modal.Body>
                </Modal>
        </Container>
    );
};

export default Users;