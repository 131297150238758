import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { cartLoadingSelector, getCartSelector, getLocalCartSelector } from '../../../store/cart/selectors';
import { productsLoadingSelector } from '../../../store/products/selectors';
import CartItem from '../CartItem/CartItem';
import styles from './CartList.module.scss';
import { AuthContext } from '../../../context/AuthContext';

const CartList = () => {
  const isAuth = useContext(AuthContext).isAuthenticated;
  // const auth = useContext(AuthContext);
  const cart = useSelector(getCartSelector);
  // console.log("🚀 ~ CartList ~ cart:", cart)
  const cartLoading = useSelector(cartLoadingSelector);
  const localCart = useSelector(getLocalCartSelector);
  // const products = useSelector( getProductsSelector );
  const productsLoading = useSelector(productsLoadingSelector);
  let cartList = null;

  // if (productsLoading) {
  //   return <Loader />;
  // }

  if (!!cart && !!cart.products && isAuth && !cartLoading && cart && cart?.products?.length > 0) {
    cartList = cart.products.map(p => (
      <CartItem key={p.product.itemNo} product={p.product} cartQuantity={p.cartQuantity} cart={cart} />
    ));
  }else if (!productsLoading && localCart && localCart.length > 0) {

    cartList = localCart.map(p => {
      // const filterProduct = products.filter(prod => {
      //   return prod['_id'] === p.product;
      // });

      return <CartItem key={p.product.id} product={p.product} cartQuantity={p.cartQuantity} cart={cart} />;
    });
  }else if ( localCart &&  !isAuth && localCart?.products?.length > 0){

        cartList = localCart.products.map(p => {
          return <CartItem key={p.product.id} product={p.product} cartQuantity={p.cartQuantity} cart={cart} />;
        });
  } 
  else {
    cartList = (
      <li key={"В корзине пока нет товаров"} style={{ textAlign: 'center', padding: '50px 0' }} className={styles.title}>
        В корзине пока нет товаров
      </li>
    );
  }
  return <ul className={styles.list}>{cartList}</ul>;
};

export default CartList;
