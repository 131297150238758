import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Container from "../../../components/Container/Container";
import "./RegistrationSuccess.scss";

const RegistrationSuccess = () => {
    const navigate = useNavigate();
    
  return (
    <>
            <Container>
                <div className="registration-success">
                <h1>Успішно зареєстровані!</h1>
                <p>Дякуємо за реєстрацію на нашому сайті.</p>
                <p>Реєстрація пройшла успішно. Вам на email відправлено лист підтвердження реєстрації</p>
                <Button
                    variant="dark"
                    type="button"
                    onClick={() => navigate('/auth')}
                    // disabled={formState}
                >
                    Увійти
                </Button>
            </div>
        </Container>
    </>
  );
};
  
//   const styles = {
//     container: {
//       width: '50%',
//       margin: 'auto',
//       textAlign: 'center',
//       paddingTop: '50px' // Додано верхній відступ для кращого вигляду
//     },
//     heading: {
//       color: 'green',
//       fontSize: width < 500? '1em':'2em',
//       marginBottom: '20px' // Додано нижній відступ для розділення заголовка від параграфів
//     },
//     paragraph: {
//       color: '#333',
//       fontSize: '1.2em',
//       marginBottom: '15px' // Додано нижній відступ між параграфами
//     }
//   };
export default RegistrationSuccess;