
import React from 'react';

export const telegram = (color = 'rgba(125,125,125, 0.5)', filled = true, width = 26, height = 26) => (
  <svg width={width} height={height} viewBox='0 0 68 68' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M41.7 21.3c-.3.1-.7.3-.9.5-6.4 4-12.7 8-19.1 12-.4.2-.4.4-.3.8.5 1.3.9 2.7 1.3 4.1l1.8 5.4h.1v-.2c.2-2 .4-3.9.5-5.9 0-.4.2-.8.5-1 2.6-2.3 5.2-4.7 7.8-7.1 2.9-2.6 5.8-5.2 8.6-7.8.2-.2.3-.4.4-.7-.2 0-.5-.1-.7-.1z'
      fill={filled ? color : 'none'}
    />
    <path
      d='M32 0C14.3 0 0 14.3 0 32s14.3 32 32 32 32-14.3 32-32S49.7 0 32 0zm17.5 17.4c-.1 1.1-.4 2.2-.6 3.3-1.8 8.7-3.7 17.4-5.5 26-.4 2.1-1.9 2.6-3.6 1.3-2.8-2.1-5.7-4.2-8.5-6.3-.1-.1-.3-.2-.4-.3-1.5 1.5-3.1 3-4.6 4.4-.5.5-1.1.8-1.9.8-.5 0-.8-.3-1-.7-1.2-3.6-2.3-7.2-3.5-10.7-.1-.3-.3-.5-.6-.6-2.7-.8-5.4-1.7-8.1-2.5-.4-.1-.8-.3-1.2-.6-.6-.4-.6-1-.1-1.4.5-.4 1-.8 1.6-1 3.3-1.3 6.7-2.6 10-3.9 8.5-3.3 16.9-6.5 25.4-9.8 1.5-.6 2.8.3 2.6 2z'
      fill={filled ? color : 'none'}
    />
  </svg>
);
