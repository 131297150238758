import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from "./MySearch.module.scss";
import { useDispatch } from 'react-redux';
import { saveloaderAction } from '../../../store/loader/actions';
import { productsLoadingAction, saveProductsAction } from '../../../store/products/actions';
import { searchProducts } from '../../../http/productAPI';
import { useNavigate } from 'react-router-dom';

const MySearch = () => {
   const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState('');
    const navigate = useNavigate();
   const search = () => {
        dispatch(saveloaderAction(true));
        dispatch(productsLoadingAction(true));
        searchProducts(searchInput).then(res => {
            setSearchInput('');
            dispatch(saveProductsAction(res.data));
            dispatch(productsLoadingAction(false));
            dispatch(saveloaderAction(false));
            navigate("/products");
        }).catch(err => {
            dispatch(productsLoadingAction(false));
            dispatch(saveloaderAction(false));
            return err;
        });
   }

  return (
    <div className={styles.search__container}>
        <input type="text" className={styles.search__input} placeholder="Пошук" value={searchInput} onChange={(e)=>setSearchInput(e.target.value)}/>
        <button className={styles.search__button} onClick={() => search()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
            </svg>
        </button>
    </div>
  );
};

export default MySearch;
