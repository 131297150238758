import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { replace } from '../../../utils/func';
import { Decimal } from 'decimal.js';
// import { PRODUCT_ROUTE } from '../../../utils/consts';
import {
  addProductToCartOperation,
  changeLocalCartOperation,
  decreaseCartProductQuantityOperation,
  deleteProductFromCartOperation,
} from '../../../store/cart/operations';
import { saveModalOrderAction } from '../../../store/modal/actions';
import styles from './CartItem.module.scss';
import { AuthContext } from '../../../context/AuthContext';

const CartItem = ({ product, cartQuantity, cart }) => {
  const { previousPrice, currentPrice, quantity, _id: id, bottling, quantityMl } = product;
  const isAuth = useContext(AuthContext).isAuthenticated;
  const admin = useContext(AuthContext).permissions?.admin?.access;
  const dispatch = useDispatch();
  const [controlQuantity, setControlQuantity] = useState(cartQuantity);
  const [clickDisabled, setClickDisabled] = useState(false);

  const decrementQuantity = () => {
    if (!clickDisabled) {
      setClickDisabled(true);
      dispatch(changeLocalCartOperation(id, 'decrease'));

      if (isAuth) {
        dispatch(decreaseCartProductQuantityOperation(admin, id));
      }
      setControlQuantity(cartQuantity - 1);

      setTimeout(() => {
        setClickDisabled(false);
      }, 500); // блокуємо клік протягом 1 секунди
    }
  };

  const incrementQuantity = () => {
    if (!clickDisabled) {
      setClickDisabled(true);
      dispatch(changeLocalCartOperation( id, 'add'));

      if (isAuth) {
        dispatch(addProductToCartOperation(admin, id));
      }
      setControlQuantity(cartQuantity + 1);      
      setTimeout(() => {
        setClickDisabled(false);
      }, 500); // блокуємо клік протягом 1 секунди
    }
  };

  const deleteProduct = () => {
    dispatch(changeLocalCartOperation(id, 'delete'));

    if (isAuth) {
      dispatch(deleteProductFromCartOperation(admin, id, cart));
    }
  };

  const calculatePrice = () => {
    let totalPrice;
    
    if (product.discount && product.discount > 0) {
      const discount = product.discount / 100;
      const sumDiscount = (cartQuantity * currentPrice * discount).toFixed(2);
      totalPrice = (cartQuantity * currentPrice - parseFloat(sumDiscount)).toFixed(2);
    } else if (product.categories?.discount && product.categories.discount.quantity !== 0) {
      const discount = product.categories.discount.quantity / 100;
      const sumDiscount = (cartQuantity * currentPrice * discount).toFixed(2);
      totalPrice = (cartQuantity * currentPrice - parseFloat(sumDiscount)).toFixed(2);
    } else {
      totalPrice = (cartQuantity * currentPrice).toFixed(2);
    }
    totalPrice = new Decimal(totalPrice)
    .toDecimalPlaces(2)
    .toString();
    return totalPrice;
  };

  const closeCart = () => {
    dispatch(saveModalOrderAction(false));
  };

  const decrementDisabled = controlQuantity <= 1;
  const incrementDisabled = !bottling ? controlQuantity >= quantity : controlQuantity >= quantityMl;

  return (
    <li className={styles.container}>
      <div className={styles.column}>
        <div className={styles.delete}>
          <span
            onClick={() => {
              deleteProduct();
            }}
            className={styles.deleteBtn}
          >
            &#128465;
          </span>
        </div>
        <NavLink to={`/products/item/${product._id}`} onClick={closeCart}>
          <img src={product.imageFirst} className={styles.image} width={78} height={78} alt='product img' />
        </NavLink>
        <div className={styles.nameBlock}>
          <NavLink  to={`/products/item/${product._id}`} onClick={closeCart}>
            <h4 className={styles.name}>{product.name}</h4>
          </NavLink>
          {previousPrice ? (
            <div className={styles.priceSales}>
              <div className={styles.currentPrice}>{replace(currentPrice)} грн</div>
              <div className={styles.previousPrice}>{replace(previousPrice)} грн</div>
            </div>
          ) : (
            <div className={styles.regularPrice}>{replace(currentPrice)} грн</div>
          )}
        </div>
      </div>
      <div className={styles.column}>
        <div>
          <div className={styles.quantityBlock} >
            <button
              disabled={decrementDisabled}
              className={styles.quantityBtn}
              type='button'
              onClick={decrementQuantity}
            >
              -
            </button>
            <input
              onChange={e => setControlQuantity(e.target.value)}
              className={styles.quantityInput}
              type='text'
              value={controlQuantity}
            />
            <button
              disabled={incrementDisabled}
              className={styles.quantityBtn}
              type='button'
              onClick={incrementQuantity}
            >
              +
            </button>
          </div>
          <span> {product.bottling ? " мл" : " шт"}</span>

          {controlQuantity === quantity && <span className={styles.ended}>Вибачте товару немає</span>}
        </div>
        <div className={styles.price}>
          <span>{calculatePrice()} грн</span>
        </div>
      </div>
    </li>
  );
};

CartItem.propTypes = {
  product: PropTypes.object.isRequired,
  cartQuantity: PropTypes.number.isRequired,
  cart: PropTypes.object,
};

CartItem.defaultProps = {
  cart: [],
};

export default CartItem;
