import React from 'react';
import './OrderAcceptedPage.scss'; // Імпорт стилів


const OrderAcceptedPage = () => {
    // const navigate = useNavigate();
  return (
    <div className="order-accepted">
      <h1 className="order-accepted-title">Замовлення прийнято</h1>
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
        <path fill="#4caf50" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#ccff90" d="M34.602,14.602L21,28.199l-5.602-5.598l-2.797,2.797L21,33.801l16.398-16.402L34.602,14.602z"></path>
        </svg>
      <p className="order-accepted-text">Дякуємо за ваше замовлення!</p>
      <p className="order-accepted-text">Ваше замовлення було успішно прийняте. Ми надішлемо вам підтвердження на електронну пошту в найближчий час.</p>
      <p className="order-accepted-text">Якщо у вас виникнуть будь-які питання, будь ласка, зв'яжіться з нашою службою підтримки.</p>
      <a href="/index">На головну</a>
      {/* <Button variant='dark' onClick={()=>navigate('/index')}>На головну</Button> */}
    </div>
  );
};

export default OrderAcceptedPage;
