import {
  SET_CATEGORIES,
  SET_CATEGORIES_MENU
  // SET_PRODUCTS_FILTERED,
  // SET_PRODUCTS_LOADING,
  // SET_ONE_PRODUCT,
  // SET_ONE_PRODUCT_LOADING,
  // SET_PRODUCTS_FILTER,
  // SET_PRODUCTS_QUANTITY,
  // // eslint-disable-next-line import/named
  // SET_PRODUCTS_STYLE,
} from './types';

const initialState = {
  allCategories: [],
  allCategoriesMenu: [],
  // filterProducts: [],
  // product: {},
  // productsFilter: { perPage: 9, startPage: 1, sort: '-date' },
  // productsQuantity: 0,
  // isLoading: true,
  // productLoading: true,
  // isGrid: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIES: {
      return { ...state, allCategories: action.payload };
    }    
    case SET_CATEGORIES_MENU: {
      return { ...state, allCategoriesMenu: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
