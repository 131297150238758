export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_CATEGORIES_MENU = 'SET_CATEGORIES_MENU';

// export const SET_PRODUCTS_FILTERED = 'SET_PRODUCTS_FILTERED';
// export const SET_ONE_PRODUCT = 'SET_ONE_PRODUCT';
// export const SET_PRODUCTS_FILTER = 'SET_PRODUCTS_FILTER';
// export const SET_PRODUCTS_QUANTITY = 'SET_PRODUCTS_QUANTITY';
// export const SET_PRODUCTS_LOADING = 'SET_PRODUCTS_LOADING';
// export const SET_ONE_PRODUCT_LOADING = 'SET_ONE_PRODUCT_LOADING';
// export const SET_PRODUCTS_STYLE = 'SET_PRODUCTS_STYLE';
