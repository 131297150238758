import React, { useState, useEffect } from 'react';
import { Table, Modal, Button } from 'react-bootstrap';
import { useHttp } from '../../../hooks/http.hook';
import { useMessageError, useMessageSuccess } from '../../../hooks/message.hook';
import FormWrapper from './FormWrapper/FormWrapper';
import Container from '../../../components/Container/Container';
import BurgerMenu from '../../../components/BurgerMenu/BurgerMenu';
import "./ProductsGroups.scss";

const ProductsGroups = () => {
    const { request } = useHttp();
    const messageError = useMessageError();
    const messageSuccess = useMessageSuccess();
    const [showModal, setShowModal] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const [delId, setDelId] = useState();
    const [selected, setSelected] = useState(null);
    const [productsGroups, setProductsGroups] = useState([]);

    const getFetchAll = async () => {
        try {
            const data = await request('admin/productsGroups/getall', 'GET');
            setProductsGroups(data.data);
        } catch (error) {
            messageError(error);
        }
    };

    useEffect(() => {
        getFetchAll();
    }, []);

    const handleDelete =  (id) => {
        setShowDel(true);
        setDelId(id)
    };
    const handleDeleteAccess = async () => {
        setShowDel(false);
        try {
            const data = await request(`admin/productsGroups/delete/${delId}`, 'DELETE');
            messageSuccess(data.message);
            setDelId("")
            getFetchAll();
        } catch (error) {
            messageError(error);
        }
    };

    const handleCreate = () => {
        setShowModal(true);
        setSelected(null);
    };
    const handleEdit = (id) => {
        setSelected(id);
        setShowModal(true);
    };
    const handleCloseModal = (success) => {
        setShowModal(false);
        if(success){getFetchAll()};
    };
    const handleCloseDel = () => {
        setShowDel(false);
        setDelId("")
    }

    return (
        <>
            <Container>                
                <BurgerMenu />
                <h2>Всі групи продуктів</h2>
                <p>Kількість: <span>{productsGroups.length}</span></p>
                <div className='d-flex justify-content-end'>
                    <Button variant="dark" className='mb-3' onClick={() => handleCreate()}>Створити</Button>{' '}
                </div>
                <div className='table__wrapper'>
                    <Table striped bordered hover className='table__main'>
                        <colgroup>
                            <col className="low" />
                            <col className="middle" />
                            <col className="middle" />
                            <col className="middle" />
                        </colgroup>
                        <thead className='table__head'>
                            <tr>
                                <th>#</th>
                                <th>Назва</th>
                                <th>Продукти</th>
                                <th>Взаємодія</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productsGroups.length > 0 && productsGroups.map((group, index) => (
                                <tr key={group._id}>
                                    <td>{index + 1}</td>
                                    <td>{group.name}</td>
                                    <td>{group.products.length}</td>
                                    <td>
                                        <Button variant="secondary" onClick={() => handleEdit(group._id)}>Редагування</Button>{' '}
                                        <Button variant="danger" onClick={() => handleDelete(group._id)}>Видалити</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                            {/* Modal for editing category */}
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selected ? "Редагування" : "Створення"} групи</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selected && <FormWrapper create={false} edit={true} id={selected} onSubmit={handleCloseModal} close={handleCloseModal}/>}
                        {!selected && <FormWrapper create={true} edit={false} id={selected} onSubmit={handleCloseModal} close={handleCloseModal}/>}
                    </Modal.Body>
                </Modal>
                <Modal show={showDel} onHide={handleCloseDel}>
                    <Modal.Header closeButton>
                        <Modal.Title>Видалити</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Button variant="danger" onClick={() => handleDeleteAccess()}>Видалити</Button>
                    </Modal.Body>
                </Modal>
            </Container>
        </>
    );
};

export default ProductsGroups;
