import React, { useState, useEffect } from 'react';
import { Table, Modal, Button } from 'react-bootstrap';
import { useHttp } from '../../../hooks/http.hook';
import { useMessageError, useMessageSuccess } from '../../../hooks/message.hook';
import FormWrapper from './FormWrapper/FormWrapper';
import Container from '../../../components/Container/Container';
import BurgerMenu from '../../../components/BurgerMenu/BurgerMenu';
import { useDispatch, useSelector } from "react-redux"
import { deleteOne } from '../../../http/statusesAPI';
import { getAllStatusesSelector } from "../../../store/statuses/selectors";
import { getStatusesOperation as getAll } from "../../../store/statuses/operations";
import { getOneStatusOperation as getOne } from "../../../store/statuses/operations";

import "./Statuses.scss";

const Statuses = () => {
    const dispatch = useDispatch();
    const messageError = useMessageError();
    const messageSuccess = useMessageSuccess();
    const [showModal, setShowModal] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const [delId, setDelId] = useState();
    const [selected, setSelected] = useState(null);
    const statuses = useSelector(getAllStatusesSelector)

    useEffect(() => {
        dispatch(getAll());
    }, []);

    const handleDelete =  (id) => {
        setShowDel(true);
        setDelId(id)
    };
    const handleDeleteAccess = async () => {
        setShowDel(false);
        try {
            deleteOne(delId).then(res => messageSuccess(res.data.message));
            setDelId("")
            dispatch(getAll());
        } catch (error) {
            messageError(error);
        }
    };

    const handleCreate = () => {
        setShowModal(true);
        setSelected(null);
    };
    const handleEdit = (id) => {
        setSelected(id);
        dispatch(getOne(id));
        setShowModal(true);
    };
    const handleCloseModal = (success) => {
        setShowModal(false);
        if(success){dispatch(getAll());};
    };
    const handleCloseDel = () => {
        setShowDel(false);
        setDelId("")
    }

    return (
        <>
            <Container>                
                <BurgerMenu />
                <h2>Всі Статуси</h2>
                <p>Kількість: <span>{statuses.length}</span></p>
                <div className='d-flex justify-content-end'>
                    <Button variant="dark" className='mb-3' onClick={() => handleCreate()}>Створити</Button>{' '}
                </div>
                <div className='table__wrapper'>
                    <Table striped bordered hover className='table__main'>
                        <colgroup>
                            <col className="low" />
                            <col className="middle" />
                            <col className="middle" />
                            <col className="middle" />
                            <col className="middle" />
                        </colgroup>
                        <thead className='table__head'>
                            <tr>
                                <th>#</th>
                                <th>Назва</th>
                                <th>Тип</th>
                                <th>Статус</th>
                                <th>Взаємодія</th>
                            </tr>
                        </thead>
                        <tbody>
                            {statuses.length > 0 && statuses.map((status, index) => (
                                <tr key={status._id}>
                                    <td>{index + 1}</td>
                                    <td>{status.name}</td>
                                    <td>{status.type}</td>
                                    <td>{status.active ? "Ввімкнений" : "Вимкнений" }</td>
                                    <td>
                                        <Button variant="secondary" onClick={() => handleEdit(status._id)}>Редагування</Button>{' '}
                                        <Button variant="danger" onClick={() => handleDelete(status._id)}>Видалити</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                            {/* Modal for editing category */}
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selected ? "Редагування" : "Створення"} знижки</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selected && <FormWrapper createStatus={false} editStatus={true} id={selected} onSubmit={handleCloseModal} close={handleCloseModal}/>}
                        {!selected && <FormWrapper createStatus={true} editStatus={false} id={selected} onSubmit={handleCloseModal} close={handleCloseModal}/>}
                    </Modal.Body>
                </Modal>
                <Modal show={showDel} onHide={handleCloseDel}>
                    <Modal.Header closeButton>
                        <Modal.Title>Видалити</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Button variant="danger" onClick={() => handleDeleteAccess()}>Видалити</Button>
                    </Modal.Body>
                </Modal>
            </Container>
        </>
    );
};

export default Statuses;
