import React from "react";
import { NavLink } from "react-router-dom";
import Icons from "../../Icons/Icons";
import OrderModal from "../../OrderModal/OrderModal";
import './NavList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { orderOpenOperation } from '../../../store/modal/operations';
import { getModalOrderSelector } from '../../../store/modal/selectors';

const NavList = React.memo(({ items, wishlist, cart}) => {
    const dispatch = useDispatch();
    const openModalOrder = () => { 
        dispatch(orderOpenOperation());
    }
    const itemsArray = items.map((item) => {
        if (item.name === "Auth") {
            return (
                <li className="link__wrapper-icon" key={item.name}>
                    <NavLink className="link__wrapper-item" to={item.url}>
                        <Icons type={item.name.toLowerCase()} color='#000' width={30} height={30} />
                    </NavLink>
                </li>
            );
        } 
        if (item.name == "Wishlist") {
            return (
                <li className="link__wrapper" key={item.name}>
                    <NavLink className="link__wrapper-item" to={item.url}>
                        <Icons type={item.name.toLowerCase()} color='#000' width={30} height={30} />
                        {(!!wishlist && wishlist?.products?.length > 0) && <small className="small__number">{wishlist.products.length}</small>}
                    </NavLink>
                </li>
            );
        }
         if(item.name == "Orders" ) {
            return (
                <li className="link__wrapper" key={item.name}>
                    <NavLink className="link__wrapper-item" 
                    // to={item.url}
                    >
                        <Icons type={item.name.toLowerCase()} color='#000' width={30} height={30} 
                            onClick={() => openModalOrder()} 
                        />
                        {(!!cart && cart?.products?.length > 0) && <small className="small__number">{cart.products.length}</small>}
                        <OrderModal />
                    </NavLink>
                </li>
            );
        }
        if( item.name == "Account") {
            return (
                <li className="link__wrapper-icon" key={item.name}>
                    <NavLink className="link__wrapper-item" to={item.url}>
                        <Icons type={item.name.toLowerCase()} color='#000' width={30} height={30} />
                    </NavLink>
                </li>
            );
        }
    });

    return (
        <ul className="links__container">
            {itemsArray}
        </ul>
    );
});

export default NavList;
