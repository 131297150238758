import React from "react";

const Gamefiacation = () =>  {
    return (
        <>
            <h1>Gamefiacation</h1>
        </>
    );
}

export default Gamefiacation;