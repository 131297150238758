import React from 'react';
import { Modal, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { getModalOrderSelector } from '../../store/modal/selectors';
import { saveModalOrderAction } from '../../store/modal/actions';
import { cartTotalPriceSelector } from '../../store/cart/selectors';

import styles from "./OrderModal.module.scss";
import CartList from './CartList/CartList';
import { NavLink } from 'react-router-dom';
import { replace } from '../../utils/func';

const OrderModal = () => {
    const dispatch = useDispatch();
    const totalPrice = replace(useSelector(cartTotalPriceSelector));

    const { isOpen, message, failed, action } = useSelector(getModalOrderSelector);
    const handleCloseModal = () => {
        dispatch(saveModalOrderAction({ isOpen: false }));
        action();
    };

    return (
        <Modal show={isOpen} onHide={handleCloseModal}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>

            <h2 className={styles.title}>Корзина</h2>
            <div className={styles.header}>
                <div className={styles.headerQuantity}>Кількість</div>
                <div className={styles.headerPrice}>Вартість</div>
            </div>

            <ul className={styles.list}>
                <CartList />
            </ul>

            <div className={`${styles.list} ${styles.checkoutContainer}`}>
                <div className={styles.backContainer}>
                <span className={styles.back} onClick={() => handleCloseModal()}>
                    &#8592; Повернутися до покупок
                </span>
                </div>
                <div className={styles.checkoutBlock}>
                <h3 className={styles.price}>
                    {totalPrice}
                    грн
                </h3>
                <NavLink to="orders" onClick={() => handleCloseModal()}>
                    <Button variant="dark">Оформити замовлення</Button>
                </NavLink>
                </div>
            </div>
            </Modal.Body>
        </Modal>
    );
};

export default OrderModal;