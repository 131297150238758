import React, { useContext, useState } from 'react';
import { AuthContext } from "../../../context/AuthContext";
import { useFormik } from 'formik';
import { useHttp } from '../../../hooks/http.hook';
import { useMessageError, useMessageSuccess } from '../../../hooks/message.hook';
import { Form, Button} from 'react-bootstrap';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const SignInForm = () => {
    const { error, request, clearError } = useHttp();
    const messageError = useMessageError();
    const messageSuccess = useMessageSuccess();
    const navigate = useNavigate();
    const [formState, setFormState] = useState(true);
    const auth = useContext(AuthContext);
  
    const formik = useFormik({
      initialValues: {
        password: '',
        email: '',
      },
      validationSchema: Yup.object({
        email: Yup.string()
          .email("Неправильний формат електронної адреси")
          .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Помилка електронної адреси")
          .required("Обов'язкове поле"),
          password: Yup.string()
          .min(7, "Пароль повинен містити щонайменше 7 символів")
          .required("Обов'язкове поле")
      }),
      onSubmit: values => {
        // alert(JSON.stringify(values, null, 2));
        // setFormState(values);
        clickSubmiting(values)
      }
    });
	const clickSubmiting = async (values) => {
    try {
      const data = await request("auth/login", "POST", { ...values });
      if(data.status){
        auth.login(data.data.token, data.data.userInfo.userId, data.data.permissions, data.data.userInfo.email);
        navigate("/");
        messageSuccess(data.message);
      }
    } catch (e) {
      messageError(e)
    }
	};

	return (
        <div>
        <Form onSubmit={formik.handleSubmit}>

          <Form.Group controlId="email" className='mb-3'>
            <Form.Label className=' d-block text-start'>Електронна пошта</Form.Label>
            <Form.Control
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              placeholder="Введіть електронну пошту"
            />
            <Form.Text className="text-danger">
              {formik.touched.email && formik.errors.email ? (
                <div className="text-danger">{formik.errors.email}</div>
              ) : null}
            </Form.Text>
          </Form.Group>

          <Form.Group className='mb-3' controlId="password">
            <Form.Label className='d-block text-start'>Пароль</Form.Label>
            <Form.Control
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              placeholder="Введіть пароль"
            />
            <Form.Text className="text-danger">
              {formik.touched.password && formik.errors.password ? (
                <div className="text-danger">{formik.errors.password}</div>
              ) : null}
            </Form.Text>
          </Form.Group>

          <Button
            variant="dark"
            type="submit"
            onClick={values => setFormState(values)}
            // disabled={formState}
          >
            Увійти
          </Button>
        </Form>
    </div>
	);
};

export default SignInForm;
