import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router';
import { confirmRegistration } from '../../../http/userAPI';
import { saveModalAuthRegAction, saveModalPopupAction } from '../../../store/modal/actions';
import { popupOpenOperation } from '../../../store/modal/operations';

const ConfirmRegistration = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    confirmRegistration(token).then(res => {
      dispatch(
        popupOpenOperation('Ваша пошта підтверджена!', false, () => {
          navigate('/index');
        })
      );
      dispatch(saveModalAuthRegAction(true));
      return res.data.message;
    });
  }, [token]);

  return null;
};

export default ConfirmRegistration;
