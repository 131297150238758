import React, { useContext, useEffect, useState } from "react";
import './Header.scss';
import NavList from "./NavList/NavList";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config/globalConfig";
import { AuthContext } from "../../context/AuthContext";
import BurgerMenuProducts from "../BurgerMenuProducts/BurgerMenu";
import { useHttp } from "../../hooks/http.hook";
import {getWishListSelector} from "../../store/wishList/selectors";
import { useDispatch, useSelector } from "react-redux";
import { getDataOperation } from "../../store/operations";
import { getCartSelector } from "../../store/cart/selectors";
import MySearch from "../MyForms/MySearch/MySearch";
import { saveCategoriesMenuAction } from "../../store/categories/actions";

const Header = () => {
    const dispatch = useDispatch()
    const [links, setLinks] = useState([]);
    const wishlist = useSelector(getWishListSelector);    
    const cartUser = useSelector(getCartSelector);    
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const { request } = useHttp();
    const [menu, setMenu] = useState([{name: "products", url: "products"}]);

    const logout = () => {
        auth.logout();
        navigate("/index");
    };

    const getCategories = async() => {
        try {
            const data = await request('openpath/categories/getall/ismenu/', 'GET');
            if(data.status){
                setMenu(data.data);
                dispatch(saveCategoriesMenuAction(data.data))
            }
        } catch (error) {
            console.log(error);
        }
    }

    const checkIsAuth = () => {
        if (!auth.isAuthenticated) {
            setLinks([
                { name: "Auth", url: "auth" },
                { name: "Orders", url: "orders" },
            ]);
        } else {
            if (auth.permissions) {
                const adminAccess = auth.permissions.admin?.access;
                const customerAccess = auth.permissions.customer?.access;

                if (adminAccess || customerAccess) {
                    const adminUrls = [];
                    const userUrls = [];
                    const mainPath = adminAccess ? "admin/" : "user/";

                    Object.entries(auth.permissions[adminAccess ? "admin" : "customer"].modules).forEach(([key, value]) => {
                        if (value.access) {
                            const name = key.charAt(0).toUpperCase() + key.slice(1);
                            const url = mainPath + key.toLowerCase();
                            (adminAccess ? adminUrls : userUrls).push({ name, url });
                        }
                    });
                    setLinks(adminAccess ? adminUrls : userUrls);
                }
            }
        }
    }
    useEffect(() => {
        getCategories();
    }, [])

    useEffect(() => {
        checkIsAuth();
    }, [auth.isAuthenticated, auth.permissions]);

    useEffect(() => {
        dispatch(getDataOperation(auth.isAuthenticated, auth.permissions?.admin?.access));
      }, [auth.isAuthenticated, auth.permissions]);
    return (
        <header className="header__container">
            <div className="header__catalogs">
            <BurgerMenuProducts menu={menu}/></div>
            {(auth.isAuthenticated && auth.permissions.admin?.access) &&  <Link className="logo__name" to="/index"><span className="logo__namehidden">{config.NAMESHOP}{auth.permissions.admin?.access && "ADMIN"}</span><span className="logo__icon"><img src="/whiskey_2172719.png" alt="ico" /></span></Link>}
            {(!auth.isAuthenticated || !auth.permissions.admin?.access) && <Link className="logo__name" to="/index"><span className="logo__namehidden">{config.NAMESHOP}</span><span className="logo__icon"><img src="/whiskey_2172719.png" alt="ico" /></span></Link>} 
            <MySearch/>
            <div className="d-flex">
                <NavList items={links} wishlist={wishlist} cart={cartUser}/>
                {auth.isAuthenticated &&
                    <div className="logo__exit" onClick={logout}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z" />
                            <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
                        </svg>
                    </div>
                }
            </div>
        </header>
    );
}

export default Header;
