import React, { useEffect, useState } from 'react';
import { useHttp } from '../../../../hooks/http.hook';
import { useMessageError, useMessageSuccess } from '../../../../hooks/message.hook';
import ProductsGroupsForm from '../../../../components/Forms/ProductsGroupsForm/ProductsGroupsForm'; // Assuming ProductsGroupsForm is a separate component

const FormWrapper = ({ edit, create, id, close}) => {
    const { request } = useHttp();
    const messageError = useMessageError();
    const messageSuccess = useMessageSuccess();
    const [initialValues, setInitialValues] = useState({
        name: "",
        products: []
    });
    
    const getById = async () => {
        try {
            const data = await request(`admin/productsGroups/get/${id}`, "GET");
            setInitialValues(data.data);
            messageSuccess(data.message);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (edit) getById();
    }, [edit]);

    const onSubmit = async (values) => {
        try {
            if (create) {
                const data = await request(`admin/productsGroups/create`, "POST", { ...values });
                messageSuccess(data.message);
                close(true);
            }

            if (edit) {
                const data = await request(`admin/productsGroups/edit/${id}`, "PUT", { ...values });
                messageSuccess(data.message);
                close(true);
            }
        } catch (e) {
            messageError(e);
        }
    };

    return (
        <div>
            <ProductsGroupsForm initialValues={initialValues} onSubmit={onSubmit} edit={edit} create={create}/>
        </div>
    );
};

export default FormWrapper;
