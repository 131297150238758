/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
import React from 'react';

export const navBag = (color = '#FFF', filled = true, width = 30, height = 50) => (
  <svg width={width} height={height} id='icon-bag' viewBox='0 0 423.416 423.416'>
    <path
      d='M420.688,145.096c-2.069-2.033-4.961-2.997-7.837-2.612H300.525V92.851c0-49.052-39.764-88.816-88.816-88.816
				s-88.816,39.764-88.816,88.816v49.633H10.565c-3.135,0-6.269,0-7.837,2.612c-2.106,2.024-3.083,4.954-2.612,7.837L39.3,367.137
				c5.474,29.881,31.275,51.746,61.649,52.245h221.518c30.461-0.749,56.208-22.787,61.649-52.767L423.3,152.933
				C423.771,150.05,422.794,147.12,420.688,145.096z M143.79,92.851c0-37.51,30.408-67.918,67.918-67.918
				c37.51,0,67.918,30.408,67.918,67.918v49.633H143.79V92.851z M363.218,364.002c-3.519,19.801-20.641,34.289-40.751,34.482
				H100.949c-20.11-0.193-37.232-14.68-40.751-34.482l-37.094-200.62h377.208L363.218,364.002z'
      fillRule='evenodd'
      clipRule='evenodd'
      fill={filled ? color : 'none'}
    />
  </svg>
);
