import React, { useEffect, useState } from "react";
import "./Profile.scss";
import { Button } from "react-bootstrap";


const Profile = ({user, editSelfInfo}) =>  {
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        secondName: '',
        email: (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, ''),
        phone: '',
        birthdate: '',
        gender: ''
      });
    const [selfData, setSelfData] = useState(false);
    const [contacts, setContacts] = useState(false);
      // Function to handle input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUserData({ ...userData, [name]: value });
    };
    
      // Function to handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        // Add your logic to submit the form data here
        // const formData = new FormData();
        // for (const key in userData) {
        //     formData.append(key, userData[key]);
        // }
        editSelfInfo(userData);
        setSelfData(false);
        setContacts(false);
    };

    useEffect(() => {
        setUserData(user)
    }, [user])

    return (
        <div className="profile-container">
            <aside>
                {(userData.lastName && userData.firstName) && <p>{userData.lastName} {userData.firstName}</p>}
                {userData.phone && <p>{userData.phone}</p>}
                {userData.email && <p>{userData.email}</p>}
            </aside>
            <h1>Особисті дані</h1>
            <div className="group-container">
                <form className="group-form" onSubmit={handleSubmit}>
                    <h3 className="group-title"><strong>Особисті дані</strong></h3>
                    <details>
                        <summary className="group-arrow"></summary>
                        <ul className="personal-data__list">
                            <li className="list-group-item">
                            <label htmlFor="lastName">Прізвище</label>
                            {!selfData && <p>{userData.lastName}</p>}
                            {selfData && <input className="inputProfile" type="text" id="lastName" name="lastName" value={userData.lastName} onChange={handleInputChange} />}
                            </li>
                            <li className="list-group-item">
                            <label htmlFor="firstName">Ім'я</label>
                            {!selfData && <p>{userData.firstName}</p>}
                            {selfData && <input className="inputProfile" type="text" id="firstName" name="firstName" value={userData.firstName} onChange={handleInputChange} />}
                            </li>
                            <li className="list-group-item">
                            <label htmlFor="secondName">По батькові</label>
                            {!selfData && <p>{userData.secondName}</p>}
                            {selfData && <input className="inputProfile" type="text" id="secondName" name="secondName" value={userData.secondName} onChange={handleInputChange} />}
                            </li>
                            <li className="list-group-item">
                            <label htmlFor="birthdate">Дата народження</label>
                            {!selfData && <p>{userData.birthdate}</p>}
                            {selfData && <input className="inputProfile" 
                                type="date" 
                                id="birthdate" 
                                name="birthdate" 
                                value={userData.birthdate} 
                                onChange={handleInputChange}  
                                max={(new Date()).toISOString().split("T")[0]} // Set max date to today
                            />}
                            </li>
                            <li className="list-group-item">
                            <label htmlFor="gender">Стать</label>
                            {!selfData && <p>{userData.gender == "Male" && "Чоловіча"} {userData.gender == "Female" && "Жіноча"}</p>}
                            {selfData && <select id="gender" name="gender" value={userData.gender} onChange={handleInputChange}>
                                <option value="Male">Чоловіча</option>
                                <option value="Female">Жіноча</option>
                            </select>}
                            </li>
                        </ul>
                        <div className="d-flex flex-wrap gap-3">
                        {!selfData && <Button variant="dark" type="button" onClick={() => setSelfData(true)}>Редагувати</Button>}
                        {selfData && <>
                            <Button variant="dark" type="submit">Зберігти</Button>
                            <Button variant="light" type="button"  onClick={() => setSelfData(false)}>Скасувати</Button>
                        </>}
                        </div>
                    </details>
                </form>
            </div>
            <div className="group-container">
                <form className="group-form" onSubmit={handleSubmit}>
                    <h3 className="group-title"><strong>Контакти</strong></h3>
                    <details>
                        <summary className="group-arrow"></summary>
                        <ul className="personal-data__list">
                            <li className="list-group-item">
                                <label htmlFor="lastName">Підтверджений номер</label>
                                {!contacts && <p>{userData.phone}</p>}
                                {contacts && <input className="inputProfile" type="text" id="phone" name="phone" value={userData.phone} onChange={handleInputChange} />}
                            </li>
                            <li className="list-group-item">
                                <label htmlFor="firstName">Електронна пошта</label>
                                {!contacts && <p>{userData.email}</p>}
                                {contacts && <input className="inputProfile" type="text" id="email" name="email" value={userData.email} onChange={handleInputChange} />}
                            </li>
                        </ul>
                        <div className="d-flex flex-wrap gap-3">
                        {!contacts && <Button variant="dark" type="button" onClick={() => setContacts(true)}>Редагувати</Button>}
                        { contacts && <>
                            <Button variant="dark" type="submit">Зберігти</Button>
                            <Button variant="light" type="button" onClick={() => setContacts(false)}>Скасувати</Button>
                        </>}
                        </div>
                    </details>
                </form>
            </div>
        </div>
      );
}

export default Profile;