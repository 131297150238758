
import React from 'react';

export const skype = (color = 'rgba(125,125,125, 0.5)', filled = true, width = 16, height = 16) => (
  <svg width={width} height={height} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.717 8.084c0 .554-.062 1.094-.178 1.615.295.583.461 1.24.461 1.937C16 14.046 14.018 16 11.575 16c-.757 0-1.47-.188-2.093-.519a7.73 7.73 0 01-1.396.127c-4.216 0-7.632-3.369-7.632-7.524 0-.519.053-1.024.154-1.513A4.305 4.305 0 010 4.363C0 1.953 1.981 0 4.425 0c.866 0 1.673.246 2.355.67.424-.073.86-.11 1.305-.11 4.215 0 7.632 3.368 7.632 7.524zM4.649 4.368c.305-.44.75-.783 1.323-1.018.566-.232 1.239-.35 2.001-.349.609 0 1.144.078 1.592.232.45.155.828.365 1.126.622.3.26.524.538.664.825.141.291.213.581.213.862 0 .27-.094.515-.28.729a.892.892 0 01-.7.324c-.253 0-.45-.068-.587-.203-.127-.125-.26-.32-.408-.6-.17-.358-.375-.64-.612-.839-.23-.193-.612-.29-1.139-.29-.488 0-.886.108-1.182.323-.285.207-.423.442-.423.723a.71.71 0 00.136.44c.097.131.234.247.407.343.18.099.365.179.549.236.19.058.508.143.944.255.553.132 1.06.278 1.508.438.454.16.847.359 1.168.59.325.235.585.538.768.899.185.362.277.81.277 1.33 0 .622-.16 1.189-.477 1.685-.315.495-.78.886-1.385 1.165-.6.275-1.316.414-2.132.414-.98 0-1.801-.19-2.443-.567a3.324 3.324 0 01-1.122-1.104c-.29-.462-.437-.922-.437-1.364 0-.277.096-.517.284-.713a.948.948 0 01.712-.294.85.85 0 01.6.232c.159.147.293.365.401.646.121.307.253.566.392.77.134.195.326.36.572.49.247.128.58.195.99.195.565 0 1.027-.134 1.374-.397.34-.257.506-.564.506-.942 0-.298-.087-.533-.263-.716a1.816 1.816 0 00-.729-.445c-.31-.108-.73-.223-1.25-.344-.71-.168-1.31-.367-1.789-.592-.49-.23-.883-.55-1.172-.95-.293-.404-.441-.914-.441-1.513 0-.57.156-1.085.464-1.528z'
      fill={filled ? color : 'none'}
    />
  </svg>
);
