import {
  SET_USER,
  SET_ALL_USER,
  SET_USER_ISAUTH,
  SET_USER_ISLOADING
} from './types';

export const saveUserIsLoadingAction = isLoading => ({ type: SET_USER_ISLOADING, payload: isLoading });
export const saveUserIsAuthAction = isAuth => ({ type: SET_USER_ISAUTH, payload: isAuth });
export const saveUserAction = data => ({ type: SET_USER, payload: data });
export const saveAllUserAction = getAll => ({ type: SET_ALL_USER, payload: getAll });